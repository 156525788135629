import styled from 'styled-components'

import { darken } from 'polished'
import Settings from '../Settings'
import { RowBetween } from '../Row'
import { /*TYPE,*/ ExternalLink } from '../../theme'
import { AutoColumn } from '../Column'
import { NavLink } from 'react-router-dom'
import { Upload } from 'react-feather'

const activeClassName = 'ACTIVE'

const StyledSwapHeader = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  padding: 12px 1rem 0px 1.5rem;
  margin-bottom: -4px;
  width: 100%;
  border-radius: 3rem;
  justify-content: space-evenly;
  max-width: 672px;
  color: ${({ theme }) => theme.text2};
`
const StyledTabLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`
const ExtNavLink = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  text-decoration: none !important;
  color: ${({ theme }) => theme.text2};
  :active, :visited {
    text-decoration: none;
  }
  :hover{
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

export default function SwapHeader({ active }: { active: 'swap' | 'add' }) {
  return (
    <StyledSwapHeader>
      <RowBetween>
      <AutoColumn gap="md" justify="flex-start">
          <ExtNavLink href={'https://wallet.polygon.technology/bridge/'}>
          <Upload size={20} />
          Bridge Assets 
        </ExtNavLink>
        </AutoColumn>
        <AutoColumn gap="md" justify="space-between">
        <StyledTabLink id={`swap-tab-link`} to={'/swap'} isActive={() => active === 'swap'}>  
          {/* <TYPE.black fontWeight={500}>Swap</TYPE.black> */}
        </StyledTabLink>
        </AutoColumn>
        <AutoColumn gap="md" justify="space-between">
          <StyledTabLink id={`addliq-tab-link`} to={'/add/ETH'} isActive={() => active === 'add'}>  
          {/* <TYPE.black fontWeight={500}>Add Liquidity</TYPE.black> */}
          </StyledTabLink>
        </AutoColumn>
        
        <AutoColumn gap="md" justify="flex-end">
          <Settings />
        </AutoColumn>
        </RowBetween>
    </StyledSwapHeader>
  )
}
