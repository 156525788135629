import { SupportedChainId } from '../constants/chains'

const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '',
  [SupportedChainId.ROPSTEN]: 'ropsten.',
  [SupportedChainId.RINKEBY]: 'rinkeby.',
  [SupportedChainId.GOERLI]: 'goerli.',
  [SupportedChainId.KOVAN]: 'kovan.',
  [SupportedChainId.OPTIMISM]: 'optimistic.',
  [SupportedChainId.OPTIMISTIC_KOVAN]: 'kovan-optimistic.',
  [SupportedChainId.MATIC]: 'matic.',
  [SupportedChainId.MUMBAI]: 'mumbai.',
  [SupportedChainId.AVALANCHE]: 'avalanche.',
  [SupportedChainId.AVALANCHE_FUJI]: 'fuji.',
}

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
  EXPLORER = 'explorer'
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(chainId: number, data: string, type: ExplorerDataType): string {
  if (chainId === SupportedChainId.ARBITRUM_ONE) {
    const baseUrl = 'https://explorer.arbitrum.io';
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `${baseUrl}/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `${baseUrl}/${data}`
      case ExplorerDataType.BLOCK:
        return `${baseUrl}/block/${data}`
      case ExplorerDataType.EXPLORER:
        return `Arbitrum Explorer`
      default:
        return `${baseUrl}/`
    }
  }

  if (chainId === SupportedChainId.ARBITRUM_RINKEBY) {
    const baseUrl = 'https://rinkeby-explorer.arbitrum.io';
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `${baseUrl}/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `${baseUrl}/address/${data}`
      case ExplorerDataType.BLOCK:
        return `${baseUrl}/block/${data}`
      case ExplorerDataType.EXPLORER:
        return `Arbitrum Explorer`
      default:
        return `${baseUrl}/`
    }
  }

  if (chainId === SupportedChainId.MATIC) {
    const baseUrl = 'https://polygonscan.com';
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `${baseUrl}/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `${baseUrl}/address/${data}`
      case ExplorerDataType.BLOCK:
        return `${baseUrl}/block/${data}`
      case ExplorerDataType.EXPLORER:
        return `Polygonscan`
      default:
        return `${baseUrl}/`
    }
  }

  if (chainId === SupportedChainId.MUMBAI) {
    const baseUrl = 'https://mumbai.PolygonScan.com';
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `${baseUrl}/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `${baseUrl}/address/${data}`
      case ExplorerDataType.BLOCK:
        return `${baseUrl}/block/${data}`
      case ExplorerDataType.EXPLORER:
        return `Polygonscan - Mumbai`
      default:
        return `${baseUrl}/`
    }
  }


  if (chainId === SupportedChainId.AVALANCHE) {
    const baseUrl = 'https://cchain.explorer.avax.network';
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `${baseUrl}/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `${baseUrl}/address/${data}`
      case ExplorerDataType.BLOCK:
        return `${baseUrl}/block/${data}`
      case ExplorerDataType.EXPLORER:
        return `Avalanche`
      default:
        return `${baseUrl}/`
    }
  }

  if (chainId === SupportedChainId.AVALANCHE_FUJI) {
    const baseUrl = 'https://cchain.explorer.avax.network';
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `${baseUrl}/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `${baseUrl}/address/${data}`
      case ExplorerDataType.BLOCK:
        return `${baseUrl}/block/${data}`
      case ExplorerDataType.EXPLORER:
        return `Avalanche - Fuji`
      default:
        return `${baseUrl}/`
    }
  }

  const baseUrl = `https://${ETHERSCAN_PREFIXES[chainId] ?? ''}etherscan.io`

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${baseUrl}/tx/${data}`

    case ExplorerDataType.TOKEN:
      return `${baseUrl}/token/${data}`

    case ExplorerDataType.BLOCK:
      return `${baseUrl}/block/${data}`

    case ExplorerDataType.ADDRESS:
      return `${baseUrl}/address/${data}`
    case ExplorerDataType.EXPLORER:
        return `Etherscan`
    default:
      return `${baseUrl}`
  }
}