import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount } from '@lbdex/sdk'
import { useMemo } from 'react'
import { LOVE, XOLOVE } from '../../constants/tokens'
import { useActiveWeb3React } from '../../hooks'
import { useLoveTokenChildContract } from '../../hooks/useContract'
import { /*NEVER_RELOAD, useMultipleContractSingleData,*/ useSingleCallResult } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
// import { useTotalSupply } from '../../data/TotalSupply'
// import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
// import { useTokenBalance } from '../wallet/hooks'
import { useLoveBoatContract } from '../../hooks/useContract'

// export const REWARDS_DURATION_DAYS = 7

// LoveBoat earn trading fees contract info
export const LOVEBOAT_FEES_INFO: {
  [chainId in ChainId]?: {
    // tokens: [Token, Token]
    loveBoatAddress: string
  }[]
} = {
  // [ChainId.MAINNET]: [
  //   {
  //     tokens: [WETH[ChainId.MAINNET], DAI],
  //     stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711',
  //   },
  // ],
  [ChainId.MUMBAI]: [
    {
      // tokens: [TLOVE, MUMBAIWMATIC], //LSLPT
      loveBoatAddress: XOLOVE.address,
    },
  ],
}

export interface LoveBoatProfitsInfo {
  // the address of the Loveboat fee contract
  // loveBoatAddress: string
  // the total amount of token staked in the contract
  loveBalanceInLoveBoat: TokenAmount
  // LOVE Rate (LOVE / xoLOVE)
  lovePerxoLove: TokenAmount
  // xoLOVE Rate (xoLOVE / LOVE)
  xoLovePerLove: TokenAmount
  // the balance of xoLOVE of an account
  xoLoveBalance: TokenAmount
  // user account's (connected wallet address) LOVE balance + fees earned
  userLoveBalanceInLoveBoat: TokenAmount
}

// gets the staking info from the network for the active chain id
export function useLoveBoatInfo(): LoveBoatProfitsInfo | undefined {
  const { chainId, account } = useActiveWeb3React()
  const LoveBoatSC = useLoveBoatContract(XOLOVE.address, true)

  // @kpdev can probably change the logic here
  const love = chainId ? LOVE[chainId] : undefined

  const accountArg = useMemo(() => [account ?? undefined], [account])

  const LoveTokenChildSC = useLoveTokenChildContract()

  // balance of total LOVE in LoveBoat
  const loveBalanceInLoveBoat = useSingleCallResult(LoveTokenChildSC, 'balanceOf', [XOLOVE.address ?? undefined])

  // balance of xoLOVE for account
  const xoLoveBalance = useSingleCallResult(LoveBoatSC, 'balanceOf', accountArg)

  const decimals: string[] | undefined = [JSBI.BigInt('1000000000000000000').toString()]

  /// returns the amount of LOVE an address has in the contract including fees earned
  const loveBalanceCall = useSingleCallResult(LoveBoatSC, 'LOVEBalance', accountArg)

  /// returns how much LOVE someone gets for depositing xoLOVE (LOVE / xoLOVE)
  const lovePerxoLoveCall = useSingleCallResult(LoveBoatSC, 'LOVEForxoLOVE', decimals)

  /// returns how much xoLOVE someone gets for depositing LOVE (xoLOVE per LOVE)
  const xoLovePerLoveCall = useSingleCallResult(LoveBoatSC, 'xoLOVEForLOVE', decimals)

  return useMemo(() => {
    if (!chainId || !love /*|| !lovePerxoLove || !xoLovePerLove*/) return undefined

    const loveBal2 = new TokenAmount(love, JSBI.BigInt(loveBalanceInLoveBoat?.result?.[0] ?? 0))
    const xoLoveBalance2 = new TokenAmount(love, JSBI.BigInt(xoLoveBalance?.result?.[0] ?? 0))
    const lovePerxoLove2 = new TokenAmount(love, JSBI.BigInt(lovePerxoLoveCall?.result?.[0] ?? 0))
    const xoLovePerLove2 = new TokenAmount(love, JSBI.BigInt(xoLovePerLoveCall?.result?.[0] ?? 0))

    const userLoveBalanceInLoveBoat2 = new TokenAmount(love, JSBI.BigInt(loveBalanceCall?.result?.[0] ?? 0))

    return {
      // loveBoatAddress: XOLOVE.address,
      loveBalanceInLoveBoat: loveBal2,
      lovePerxoLove: lovePerxoLove2,
      xoLovePerLove: xoLovePerLove2,
      xoLoveBalance: xoLoveBalance2,
      userLoveBalanceInLoveBoat: userLoveBalanceInLoveBoat2,
    }
  }, [chainId, love, xoLoveBalance, loveBalanceInLoveBoat, lovePerxoLoveCall, xoLovePerLoveCall, loveBalanceCall])
}
// export function useTotalUniEarned(): TokenAmount | undefined {
//   const { chainId } = useActiveWeb3React()
//   const love = chainId ? LOVE[chainId] : undefined
//   const stakingInfos = useLoveBoatFeeInfo()

//   return useMemo(() => {
//     if (!love) return undefined
//     return (
//       stakingInfos?.reduce(
//         (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
//         new TokenAmount(love, '0')
//       ) ?? new TokenAmount(love, '0')
//     )
//   }, [stakingInfos, love])
// }

// based on typed value
export function useDerivedXoloveInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect to a wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error,
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect to a wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error,
  }
}
