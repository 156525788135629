import styled from 'styled-components'
import { Feature1, Feature2, Feature3, Feature5 } from '../../components/FeatureCard/'
import { AutoColumn, InfoSection, InfoCard } from '../../components/Column'
import { RowBetween } from '../../components/Row'
import { TYPE } from 'theme'
import Banner from '../../assets/svg/banner-words.svg'
import { ExternalLink } from '../../theme'
import { ExternalInfoButton } from '../../theme/components'

const EipLink = styled(ExternalLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.text1};
  outline: none;
  cursor: pointer;
`
const PageWrapper = styled(AutoColumn)`
  max-width: 960px;
  width: 100%;
`

const FeatureSection = styled(AutoColumn)`
  max-width: 960px;
  width: 100%;
  border-radius: 12px;
  padding: 16px 0;
`

const FeatureCardSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  row-gap: 10px;
  width: 100%;
  justify-self: center;
`

const MainBanner = styled.div`
  max-width: 672px;
  diplay: block;
  content: url(${Banner});
  align-items: center;
  margin: 0 auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding-top: 25px;
      max-width: 95%;
   `};
`

export default function Home() {
  return (
    <>
      <PageWrapper gap="lg" justify="center">
        <MainBanner />
        <InfoSection gap="lg">
          <InfoCard>
            <AutoColumn gap="md" justify="center">
              <RowBetween>
                <TYPE.white fontSize={24} fontWeight={1000} style={{ margin: '0 auto' }}>
                  Swap, Farm, and Earn
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={16} style={{ margin: '0 auto', textAlign: 'center' }}>
                  Obtain 10 Love Tokens in order to join the{' '}
                  <EipLink id={`docs-fruitful-link`} href={'https://docs.fruitful.gg/'}>
                    Fruitful Metaverse
                  </EipLink>
                  .
                </TYPE.white>
              </RowBetween>
              <ExternalInfoButton target="_self" href="/#/swap">
                Launch App
              </ExternalInfoButton>
            </AutoColumn>
          </InfoCard>
        </InfoSection>
        <FeatureSection gap="lg" justify="center">
          <TYPE.featureSectionTitle>Love Boat&apos;s Features</TYPE.featureSectionTitle>
          <FeatureCardSection>
            <Feature1 />
            <Feature2 />
            <Feature3 />
          </FeatureCardSection>
          <TYPE.featureSectionTitle>Coming Soon</TYPE.featureSectionTitle>
          <FeatureCardSection>
            {/* <Feature4 /> */}
            <Feature5 />
            {/* <Feature6 /> */}
          </FeatureCardSection>
        </FeatureSection>
        <ExternalLink id={`meet-the-team-link`} href={'https://docs.fruitful.gg/community/core-team/whos-on-the-team'}>
          <TYPE.subTitle>Meet the Love Boat Team</TYPE.subTitle>
        </ExternalLink>
      </PageWrapper>
    </>
  )
}
