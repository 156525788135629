import { useCallback, useState } from 'react'
import { AutoColumn, InfoSection, InfoCard } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, TokenAmount, ETHER } from '@lbdex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween, RowBetweenStats, RowFixed, AutoRow } from '../../components/Row'
import { CardSection, CardNoise, CardBGImage } from '../../components/Rewards/styled'
import { ButtonOrange, ButtonBlue } from '../../components/Button'
import StakingModal from '../../components/Rewards/StakingModal'
import { useStakingInfo } from '../../state/stake/hooks'
import UnstakingModal from '../../components/Rewards/UnstakingModal'
import ClaimRewardModal from '../../components/Rewards/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
// import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'
import { LightCard } from '../../components/Card/'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO, BIG_INT_SECONDS_IN_WEEK } from '../../constants'
import { ButtonDarkBlue } from '../../components/Button'
import { ExternalInfoButton } from '../../theme/components'

const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
`

// const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
const StyledPositionCard = styled(LightCard)`
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.bg1};
  margin: 0 !important;
  overflow: hidden;
`

const RewardsPositionCard = styled(LightCard)`
  width: 100%;
  border: none;
  background: linear-gradient(to right, #faa452, #f16ba2);
  margin: 0 !important;
  overflow: hidden;
`

const YourRewardsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  width: 100%;
  justify-self: center;
`

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 1rem;
`

export const HeaderRow = styled(RowBetween)`
  padding-bottom: 14px;
`

export const Buttons = styled.div`
  padding: 28px 16px 0 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  justify-content: center;
  align-items: center;
`
const ResponsiveButtonPrimary = styled(ButtonOrange)`
  width: fit-content;
  padding: 8px 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`
const ResponsiveButtonBlue = styled(ButtonBlue)`
  width: fit-content;
  padding: 8px 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`
export const ButtonRow = styled(RowFixed)`
  gap: 8px;
  padding-top: 20px;
`

export default function Manage({
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { account, chainId } = useActiveWeb3React()

  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  // console.log(tokenA)
  // console.log(tokenB)
  const [, stakingTokenPair] = usePair(tokenA, tokenB)
  // console.log(stakingTokenPair)
  const stakingInfo = useStakingInfo(stakingTokenPair)?.[0]

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))
  // const showAddLiquidityButton = true
  // console.log(account)
  // console.log(userLiquidityUnstaked.toSignificant(6))
  // console.log(userLiquidityUnstaked)
  // console.log(showAddLiquidityButton)

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  //const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  // const token = currencyA === ETHER ? tokenB : tokenA
  const WETH = currencyA === ETHER ? tokenA : tokenB
  // const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <>
      <TYPE.pageTitle>Rewards</TYPE.pageTitle>
      <PageWrapper gap="lg" justify="center">
        <InfoSection>
          {showAddLiquidityButton && (
            <InfoCard>
              <CardNoise />
              <CardBGImage />
              <CardSection>
                <AutoColumn gap="md" justify="center">
                  <RowBetween>
                    <TYPE.white fontSize={24} fontWeight={1000} style={{ margin: '0 auto' }}>
                      Get LOVE Liquidity Provider tokens
                    </TYPE.white>
                  </RowBetween>
                  <RowBetween>
                    <TYPE.white fontSize={16} style={{ margin: '0 auto' }}>
                      {`LOVE LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
                    </TYPE.white>
                  </RowBetween>{' '}
                  <ButtonDarkBlue
                    padding="8px"
                    borderradius="8px"
                    width={'fit-content'}
                    as={Link}
                    to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
                  >
                    {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
                  </ButtonDarkBlue>
                </AutoColumn>
              </CardSection>
              <CardBGImage />
              <CardNoise />
            </InfoCard>
          )}
        </InfoSection>

        {stakingInfo && (
          <>
            <StakingModal
              isOpen={showStakingModal}
              onDismiss={() => setShowStakingModal(false)}
              stakingInfo={stakingInfo}
              userLiquidityUnstaked={userLiquidityUnstaked}
            />
            <UnstakingModal
              isOpen={showUnstakingModal}
              onDismiss={() => setShowUnstakingModal(false)}
              stakingInfo={stakingInfo}
            />
            <ClaimRewardModal
              isOpen={showClaimRewardModal}
              onDismiss={() => setShowClaimRewardModal(false)}
              stakingInfo={stakingInfo}
            />
          </>
        )}
        <YourRewardsSection>
          <StyledPositionCard /*bgColor={backgroundColor}*/>
            <StatContainer>
              <HeaderRow>
                <AutoRow gap="8px">
                  <TYPE.textmode fontWeight={700} fontSize={24}>
                    Your Liquidity Deposits:
                  </TYPE.textmode>
                </AutoRow>
                <RowFixed gap="8px"></RowFixed>
              </HeaderRow>
              <RowBetweenStats>
                <TYPE.textmode style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                  LOVE LP {currencyA?.symbol}-{currencyB?.symbol}
                </TYPE.textmode>
                <TYPE.textmode>
                  {stakingInfo?.stakedAmount?.toSignificant(6) ?? 'You have no deposits of this LOVE LP pair.'}
                </TYPE.textmode>
              </RowBetweenStats>
            </StatContainer>
            <Buttons>
              <ButtonOrange
                id="add-liquidity-button"
                as={Link}
                borderradius="12px"
                to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
              >
                Add Liquidity
              </ButtonOrange>
            </Buttons>
          </StyledPositionCard>
          <RewardsPositionCard>
            <StatContainer>
              <HeaderRow>
                <AutoRow gap="8px">
                  <TYPE.textmode fontWeight={700} fontSize={24}>
                    Your Unclaimed LOVE Tokens:
                  </TYPE.textmode>
                </AutoRow>
                <RowFixed gap="8px"></RowFixed>
              </HeaderRow>
              <RowBetweenStats>
                <TYPE.textmode style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.textmode>
                <TYPE.textmode>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {stakingInfo?.active
                    ? stakingInfo?.rewardRate
                        ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                        ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'
                    : '0'}
                  {' LOVE / week'}
                </TYPE.textmode>
              </RowBetweenStats>
            </StatContainer>
            <Buttons>
              {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                <ButtonDarkBlue
                  padding="8px"
                  borderradius="8px"
                  width="fit-content"
                  onClick={() => setShowClaimRewardModal(true)}
                >
                  Claim Your Tokens
                </ButtonDarkBlue>
              )}
              {stakingInfo?.earnedAmount && JSBI.equal(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                <ExternalInfoButton href="https://loveboatexchange.gitbook.io/love-boat-exchange/love-boat-exchange/farming-on-love-boat">
                  Learn More
                </ExternalInfoButton>
              )}
            </Buttons>
          </RewardsPositionCard>
        </YourRewardsSection>
        <TYPE.subTitle>Liquidity Mining</TYPE.subTitle>
        <StyledPositionCard>
          <StatContainer>
            <HeaderRow>
              <AutoRow gap="8px">
                <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
                <TYPE.textmode fontWeight={700} fontSize={24}>
                  {currencyA?.symbol}-{currencyB?.symbol}
                </TYPE.textmode>
              </AutoRow>
              <RowFixed gap="8px"></RowFixed>
            </HeaderRow>
            <RowBetweenStats>
              <TYPE.textmode style={{ paddingBottom: '5px', paddingTop: '5px' }}>Total deposits</TYPE.textmode>
              <TYPE.textmode>
                {valueOfTotalStakedAmountInUSDC
                  ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                  : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ETH`}
              </TYPE.textmode>
            </RowBetweenStats>
            <RowBetweenStats>
              <TYPE.textmode style={{ paddingBottom: '5px', paddingTop: '5px' }}>Pool rate</TYPE.textmode>
              <TYPE.textmode>
                {stakingInfo?.active
                  ? stakingInfo?.totalRewardRate
                      ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                      ?.toFixed(0, { groupSeparator: ',' }) ?? '-'
                  : '0'}
                {' LOVE / week'}
              </TYPE.textmode>
            </RowBetweenStats>
          </StatContainer>
          <Buttons>
            {!showAddLiquidityButton && (
              <ButtonRow>
                {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
                  <>
                    <ResponsiveButtonBlue onClick={() => setShowUnstakingModal(true)}>Withdraw</ResponsiveButtonBlue>
                  </>
                )}
                {stakingInfo && (
                  /* stakingInfo.active && */ <ResponsiveButtonPrimary onClick={handleDepositClick}>
                    {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit LOVE LP Tokens'}
                  </ResponsiveButtonPrimary>
                )}
              </ButtonRow>
            )}
            {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
              /* !stakingInfo?.active ? null : */ <TYPE.main>
                {userLiquidityUnstaked.toSignificant(6)} LOVE LP tokens available
              </TYPE.main>
            )}
          </Buttons>
        </StyledPositionCard>
        <TYPE.navy style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you withdraw, the contract will automatically claim LOVE on your behalf!
        </TYPE.navy>
      </PageWrapper>
    </>
  )
}
