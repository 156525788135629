import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH, Pair } from '@lbdex/sdk'
import { useMemo } from 'react'
import {
  DAI,
  USDC,
  USDT,
  WBTC,
  MUMBAIWMATIC,
  LOVE,
  TLOVE,
  XOLOVE,
  WBTC_MUMBAI,
  LOVE_MUMBAI,
  LOVE_MATIC,
  WETH_MATIC,
  WMATIC_MATIC,
  USDC_MATIC,
  WBTC_MATIC,
  QUICK_MATIC,
  DAI_MATIC,
  USDT_MATIC,
  LINK_MATIC,
  GHST_MATIC,
  AAVE_MATIC,
  RENDOGE_MATIC,
  GRT_MATIC,
  KOM_MATIC,
} from '../../constants/tokens'

import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'

export const STAKING_GENESIS = 1627804211 // 8/1/2021 3:50 AM EST is the genesis of the first rewards contract. We staggered the release of the rewards.

export const REWARDS_DURATION_DAYS = 7

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    legacy: boolean
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      tokens: [WETH[ChainId.MAINNET], DAI],
      stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711',
      legacy: false,
    },
    {
      tokens: [WETH[ChainId.MAINNET], USDC],
      stakingRewardAddress: '0x7FBa4B8Dc5E7616e59622806932DBea72537A56b',
      legacy: false,
    },
    {
      tokens: [WETH[ChainId.MAINNET], USDT],
      stakingRewardAddress: '0x6C3e4cb2E96B01F4b866965A91ed4437839A121a',
      legacy: false,
    },
    {
      tokens: [WETH[ChainId.MAINNET], WBTC],
      stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e',
      legacy: false,
    },
  ],
  [ChainId.MUMBAI]: [
    // {
    //   tokens: [LOVE[ChainId.MUMBAI] as Token, MUMBAIWMATIC],
    //   stakingRewardAddress: '0xeD061f87c7Ba7AF672301Dc835433E7C04C5a6c3',
    //   legacy: false
    // },
    // {
    //   tokens: [LOVE[ChainId.MUMBAI]?, ],
    //   stakingRewardAddress: XOLOVE.address,
    //   legacy: false
    // },
    {
      tokens: [TLOVE, XOLOVE],
      stakingRewardAddress: '0xeD061f87c7Ba7AF672301Dc835433E7C04C5a6c3',
      legacy: false,
    },
    {
      tokens: [TLOVE, MUMBAIWMATIC], //LSLPT
      stakingRewardAddress: '0xf3cA87075Ff2c302C5ad743beAf6409a1Ce730E9',
      legacy: false,
    },
    {
      tokens: [LOVE_MUMBAI, WBTC_MUMBAI], //WBTC LOVE (0x9)
      stakingRewardAddress: '0x5b16786c5F728deCAE842e9Fc9174D57ae798655',
      legacy: false,
    },
  ],
  [ChainId.MATIC]: [
    {
      tokens: [LOVE_MATIC, WMATIC_MATIC],
      stakingRewardAddress: '0x1eC753dE7B88C5F2a3ab143642495A5ee2221FBf',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, WETH_MATIC],
      stakingRewardAddress: '0x55D19480514667288aE39f55938AcdaF4b3eB11e',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, USDC_MATIC],
      stakingRewardAddress: '0x27E70eD8FF2f3ABCA58503A323cA72adFc2712C0',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, KOM_MATIC],
      stakingRewardAddress: '0x51a4980f3E4F8796B71114ED21fC3924548E48b6',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, DAI_MATIC],
      stakingRewardAddress: '0xeE772d27892dF6c8E1C584031e980a356023CCbb',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, USDT_MATIC],
      stakingRewardAddress: '0xb145E2f781e99F1A876f67279516c03624b3AbB0',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, LINK_MATIC],
      stakingRewardAddress: '0xB6F29e24E979DDB32da335EFBf50B76bcE2Beb19',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, GHST_MATIC],
      stakingRewardAddress: '0xECdb81dA597F61E7Df01c10e7b7039e59704F793',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, AAVE_MATIC],
      stakingRewardAddress: '0xBf6636c189e2BA3B88aCc9fB41c3A37dBc08A4B6',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, RENDOGE_MATIC],
      stakingRewardAddress: '0x719e35aBdC9994FBA92A9d50F10acb32d0AE80B4',
      legacy: false,
    },
    {
      tokens: [LOVE_MATIC, GRT_MATIC],
      stakingRewardAddress: '0xE0A891bB272C2170DA238eb7FDFD1eE5F9eBeB18',
      legacy: false,
    },
    {
      tokens: [WETH_MATIC, USDC_MATIC],
      stakingRewardAddress: '0xA54Efd3BDf02A67b4ac55A042570933c7FC7164A',
      legacy: false,
    },
    {
      tokens: [WETH_MATIC, WMATIC_MATIC],
      stakingRewardAddress: '0xd1629C43952Cb071FB7F71663EA70C6561997566',
      legacy: false,
    },
    {
      tokens: [WETH_MATIC, WBTC_MATIC],
      stakingRewardAddress: '0x8023eAef24a8B36ddd33F49FB894df928dA00c13',
      legacy: false,
    },
    {
      tokens: [WMATIC_MATIC, QUICK_MATIC],
      stakingRewardAddress: '0x13054DB1E896023bFE5EeBB3136fF09a26D42F59',
      legacy: false,
    },
    // {
    //   tokens: [USDC_MATIC, USDT_MATIC],
    //   stakingRewardAddress: '0x066e895639B8A8e186893bA74C17C165efF3731b',
    //   legacy: false,
    // },
    // {
    //   tokens: [USDC_MATIC, DAI_MATIC],
    //   stakingRewardAddress: '0x45B4aEcebd0a4c455a33762C4B16c54B85065D81',
    //   legacy: false,
    // },
    // {
    //   tokens: [USDT_MATIC, DAI_MATIC],
    //   stakingRewardAddress: '0x683F4C423c5BF054139cb46aeB539Ce594eb579F',
    //   legacy: false,
    // },

    // Legacy Addresses
    // {
    //   tokens: [LOVE_MATIC, WMATIC_MATIC],
    //   stakingRewardAddress: '0xa501cFCe199bd3778d448eaD355359def221437A',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, WETH_MATIC],
    //   stakingRewardAddress: '0xCe2E8A741A701C0b2bffF7b6687Ce96fD3dABBcd',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, USDC_MATIC],
    //   stakingRewardAddress: '0xA8b3ea5FD139a65F4ff806fa33070a5bFCce4f57',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, DAI_MATIC],
    //   stakingRewardAddress: '0xF7561a52Edb450159561Fa8B90B68f17bb6dcd17',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, USDT_MATIC],
    //   stakingRewardAddress: '0x86A20fFB4b63DdD05077B4d88819971dF5d2668f',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, LINK_MATIC],
    //   stakingRewardAddress: '0x7729d27eA3A48247a7746244D8da79cf86CD4A29',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, GHST_MATIC],
    //   stakingRewardAddress: '0x184Dc58e71C2A4c4D9FFE6Dd4c9E5dAA5208Da90',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, AAVE_MATIC],
    //   stakingRewardAddress: '0xE093C2f048B77F0dC312FCcbf764085F85af6dEC',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, RENDOGE_MATIC],
    //   stakingRewardAddress: '0x062787C31dc6A87211f2FC264B6f42F676513E59',
    //   legacy: true
    // },
    // {
    //   tokens: [LOVE_MATIC, GRT_MATIC],
    //   stakingRewardAddress: '0xdbA343Aa54983971d4a3EdCD68086CAEe0D80b5c',
    //   legacy: true
    // },
    // {
    //   tokens: [WETH_MATIC, USDC_MATIC],
    //   stakingRewardAddress: '0xaa20ebf5bB0197525A8422B88A6BF93BFaBDAcb7',
    //   legacy: true
    // },
    // {
    //   tokens: [WETH_MATIC, WMATIC_MATIC],
    //   stakingRewardAddress: '0x1eaB55565eB2Ed92931ea9F8Fb2D67BcCB810a9A',
    //   legacy: true
    // },
    // {
    //   tokens: [WETH_MATIC, WBTC_MATIC],
    //   stakingRewardAddress: '0xc9258f9b0a31cCdf39A324eeb396B37e6BfFf997',
    //   legacy: true
    // },
    // {
    //   tokens: [WMATIC_MATIC, QUICK_MATIC],
    //   stakingRewardAddress: '0x234D71277Ac881dCb5f942D3d8390f6b213924f8',
    //   legacy: true
    // },
    // {
    //   tokens: [USDC_MATIC, USDT_MATIC],
    //   stakingRewardAddress: '0xaB3283bDa89d6E56Dc31A10897025d4c72180Ae2',
    //   legacy: true
    // },
    // {
    //   tokens: [USDC_MATIC, DAI_MATIC],
    //   stakingRewardAddress: '0xD3F0d953ba9173631f022852B2795afD8014079c',
    //   legacy: true
    // },
    // {
    //   tokens: [USDT_MATIC, DAI_MATIC],
    //   stakingRewardAddress: '0x9d35703aB5e02a7303eb1891e3572ec8B67a434c',
    //   legacy: true
    // },
  ],
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // if pool is active
  active: boolean
  legacy: boolean
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()
  // console.log(account)
  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter((stakingRewardInfo) =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  // @kpdev can probably change the logic here
  const love = chainId ? LOVE[chainId] : undefined
  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // console.log('state>stake>hooks.ts line 121')
  // console.log(balances)
  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !love) return []
    // if (!chainId || !love) return (console.error('Failed to load staking rewards info'))

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          console.log(index)
          console.log(totalSupplyState)
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(love, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            love,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        //const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
        const periodFinishSeconds = periodFinishState.result?.[0]?.toString()
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: rewardsAddress,
          legacy: info[index].legacy,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(love, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate,
          active,
        })
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    love,
  ])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const love = chainId ? LOVE[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!love) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(love, '0')
      ) ?? new TokenAmount(love, '0')
    )
  }, [stakingInfos, love])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect to a wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error,
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect to a wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error,
  }
}
