import { AutoColumn } from '../Column'
import { RowBetween, RowBetweenStats } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { ETHER, JSBI, TokenAmount } from '@lbdex/sdk'
import { ButtonOrange } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
// import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { CardNoise, CardBGImage } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_SECONDS_IN_DAY, BIG_INT_SECONDS_IN_WEEK } from '../../constants'
import { LOVE } from '../../constants/tokens'
import { useActiveWeb3React } from '../../hooks'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 1rem;
  padding-bottom:10px;
`
const Wrapper = styled(AutoColumn)<{ showBackground: boolean /*; bgColor: any*/ }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 90px;
  grid-gap: 0px;
  height: 75px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
`
// #37445d
// const BottomSection = styled.div<{ showBackground: boolean }>`
//   padding: 12px 16px;
//   opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
//   border-radius: 0 0 12px 12px;
//   display: flex;
//   flex-direction: row;
//   align-items: baseline;
//   justify-content: space-between;
//   z-index: 1;
// `

export default function PoolCard({ stakingInfo }: { stakingInfo: StakingInfo }) {
  const { chainId } = useActiveWeb3React()
  const love = chainId ? LOVE[chainId] : undefined
  const lovePrice = useUSDCPrice(love)

  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // const token = currency0 === ETHER ? token1 : token0
  const WETH = currency0 === ETHER ? token0 : token1
  // the above is basically assuming one of them is gonig to be
  // get the color of the token
  // const backgroundColor = useColor(token)

  // console.log(`"token": ${token?.symbol}`)
  // console.log(`"WETH": ${WETH?.symbol}`)
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)

  const [, stakingTokenPair] = usePair(...stakingInfo.tokens)

  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }
  // console.log(`Staking token pair: ${stakingTokenPair?.token0?.symbol}-${stakingTokenPair?.token1?.symbol}`)

  // console.log(
  //   `Total Supply of staking token pair ${stakingTokenPair?.token0?.symbol}-${stakingTokenPair?.token1?.symbol}: ${totalSupplyOfStakingToken?.raw}`
  // )
  // console.log(`Rewards - PoolCard - valueOfTotalStakedAmountInWETH ${valueOfTotalStakedAmountInWETH?.currency.symbol}`)
  // console.log(valueOfTotalStakedAmountInWETH)

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  // const APR =
  //   valueOfTotalStakedAmountInUSDC &&
  //   lovePrice
  //     ?.quote(stakingInfo.totalRewardRate)
  //     ?.multiply(BIG_INT_SECONDS_IN_DAY)
  //     // ?.multiply(JSBI.BigInt(100))
  //     ?.divide(valueOfTotalStakedAmountInUSDC)
  //     ?.add(JSBI.BigInt(1))
  // console.log('Rewards - PoolCard - USDPrice')
  // console.log(USDPrice)
  // console.log(`Rewards - PoolCard - valueOfTotalStakedAmountInUSDC ${valueOfTotalStakedAmountInUSDC?.currency.symbol}`)
  // console.log(valueOfTotalStakedAmountInUSDC)

  return (
    <Wrapper showBackground={true} /*bgColor={backgroundColor}*/>
      <CardBGImage desaturate />
      <CardNoise />
      <TopSection>
        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} margin={true} />
        <TYPE.textmode fontWeight={500} fontSize={24} style={{ marginLeft: '4px' }}>
          {currency0.symbol}-{currency1.symbol}
        </TYPE.textmode>
        <StyledInternalLink to={`/rewards/${currencyId(currency0)}/${currencyId(currency1)}`} style={{ width: '100%' }}>
          <ButtonOrange padding="8px" borderradius="25px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonOrange>
        </StyledInternalLink>
      </TopSection>
      <StatContainer>
        <RowBetweenStats>
          <TYPE.statTitle>Total deposits</TYPE.statTitle>
          <TYPE.statValue>
            {valueOfTotalStakedAmountInUSDC
              ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
              : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ${
                  valueOfTotalStakedAmountInWETH?.token.symbol
                }`}
          </TYPE.statValue>
        </RowBetweenStats>
        <RowBetweenStats>
          <TYPE.statTitle>Reward rate in $</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo
              ? stakingInfo.active
                ? `$${lovePrice
                    ?.quote(stakingInfo.totalRewardRate)
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)

                    ?.toFixed(2, { groupSeparator: ',' })} / week`
                : '$0 / week'
              : '-'}
          </TYPE.statValue>
        </RowBetweenStats>
        <RowBetweenStats>
          <TYPE.statTitle>Reward rate in LOVE</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo
              ? stakingInfo.active
                ? `${stakingInfo.totalRewardRate
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.toFixed(0, { groupSeparator: ',' })} LOVE / week`
                : '0 LOVE / week'
              : '-'}
          </TYPE.statValue>
        </RowBetweenStats>
        <RowBetweenStats>
          <TYPE.statTitle>Your reward per $100 deposit</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo && valueOfTotalStakedAmountInUSDC?.greaterThan(JSBI.BigInt(0))
              ? stakingInfo.active
                ? `$${lovePrice
                    ?.quote(stakingInfo.totalRewardRate)
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.multiply(JSBI.BigInt(100))
                    ?.divide(valueOfTotalStakedAmountInUSDC)

                    ?.toFixed(2, { groupSeparator: ',' })} / week`
                : '$0 / week'
              : '-'}{' '}
          </TYPE.statValue>
        </RowBetweenStats>
        {isStaking && (
        <>
            <RowBetweenStats>
              <TYPE.statTitle>Your rate</TYPE.statTitle>
              <TYPE.statValue >
                <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                  ⚡
                </span>
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.rewardRate
                        ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                        ?.toSignificant(4, { groupSeparator: ',' })} LOVE / week`
                    : '0 LOVE / week'
                  : '-'}
              </TYPE.statValue>
            </RowBetweenStats>
            <RowBetweenStats>
              <TYPE.statTitle>Your rate in $</TYPE.statTitle>
              <TYPE.statValue style={{ textAlign: 'right' }} fontWeight={500}>
                <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                  ⚡
                </span>
                {stakingInfo
                  ? stakingInfo.active
                    ? `$${lovePrice
                        ?.quote(stakingInfo.rewardRate)
                        ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                        ?.toSignificant(4, { groupSeparator: ',' })} / week`
                    : '0 LOVE / week'
                  : '-'}
              </TYPE.statValue>
            </RowBetweenStats>
        </>
      )}
        <RowBetweenStats>
          <TYPE.statTitle>Daily APR</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo && valueOfTotalStakedAmountInUSDC?.greaterThan(JSBI.BigInt(0))
              ? stakingInfo.active
                ? `${lovePrice
                    ?.quote(stakingInfo.totalRewardRate)
                    ?.multiply(BIG_INT_SECONDS_IN_DAY)
                    ?.multiply(JSBI.BigInt(100))
                    ?.divide(valueOfTotalStakedAmountInUSDC)
                    ?.toFixed(2, { groupSeparator: ',' })}%`
                : '0%'
              : '-'}{' '}
          </TYPE.statValue>
        </RowBetweenStats>
        <RowBetweenStats>
          <TYPE.statTitle>Weekly APR</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo && valueOfTotalStakedAmountInUSDC?.greaterThan(JSBI.BigInt(0))
              ? stakingInfo.active
                ? `${lovePrice
                    ?.quote(stakingInfo.totalRewardRate)
                    ?.multiply(BIG_INT_SECONDS_IN_DAY)
                    ?.multiply(JSBI.BigInt(100))
                    ?.divide(valueOfTotalStakedAmountInUSDC)
                    ?.multiply(JSBI.BigInt(7))
                    ?.toFixed(2, { groupSeparator: ',' })}%`
                : '0%'
              : '-'}{' '}
          </TYPE.statValue>
        </RowBetweenStats>
        <RowBetweenStats>
          <TYPE.statTitle>30 Day APR</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo && valueOfTotalStakedAmountInUSDC?.greaterThan(JSBI.BigInt(0))
              ? stakingInfo.active
                ? `${lovePrice
                    ?.quote(stakingInfo.totalRewardRate)
                    ?.multiply(BIG_INT_SECONDS_IN_DAY)
                    ?.multiply(JSBI.BigInt(100))
                    ?.divide(valueOfTotalStakedAmountInUSDC)
                    ?.multiply(JSBI.BigInt(30))
                    ?.toFixed(2, { groupSeparator: ',' })}%`
                : '0%'
              : '-'}{' '}
          </TYPE.statValue>
        </RowBetweenStats>
        <RowBetween>
          <TYPE.statTitle>365 Day APR</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo && valueOfTotalStakedAmountInUSDC?.greaterThan(JSBI.BigInt(0))
              ? stakingInfo.active
                ? `${lovePrice
                    ?.quote(stakingInfo.totalRewardRate)
                    ?.multiply(BIG_INT_SECONDS_IN_DAY)
                    ?.multiply(JSBI.BigInt(100))
                    ?.divide(valueOfTotalStakedAmountInUSDC)
                    ?.multiply(JSBI.BigInt(365))
                    ?.toFixed(2, { groupSeparator: ',' })}%`
                : '0%'
              : '-'}{' '}
          </TYPE.statValue>
        </RowBetween>
        {/* <RowBetween>
          <TYPE.statTitle>APY (compounding yearly)</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo && valueOfTotalStakedAmountInUSDC
              ? stakingInfo.active
                ? `${lovePrice
                    ?.quote(stakingInfo.totalRewardRate)
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.multiply(JSBI.BigInt(100))
                    ?.divide(valueOfTotalStakedAmountInUSDC)
                    ?.multiply(JSBI.BigInt(52))
                    ?.toFixed(2, { groupSeparator: ',' })}%`
                : '0%'
              : '-'}{' '}
          </TYPE.statValue>
        </RowBetween> */}
        {/* <RowBetween>
          <TYPE.statTitle>APY (compounding daily)</TYPE.statTitle>
          <TYPE.statValue>
            {stakingInfo && valueOfTotalStakedAmountInUSDC
              ? stakingInfo.active && APR
                ? `${
                    JSBI.BigInt(APR.)
                    // JSBI.exponentiate(JSBI.BigInt(APR), JSBI.BigInt(365))
                    // ?.multiply(JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(365)))
                    // ?.multiply(JSBI.BigInt(52))
                  }%`
                : '0%'
              : '-'}{' '}
          </TYPE.statValue>
        </RowBetween> */}
      </StatContainer>
    </Wrapper>
  )
}
