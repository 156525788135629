import { ChainId, Currency, Token, ETHER } from '@lbdex/sdk'
import { useMemo } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'

import EthereumLogo from '../../assets/images/polygon-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  //background-color: ${({ theme }) => theme.white};
`

function setTokenLogo(address: string, chainId?: ChainId, symbol?: string) {
  let strPath = ''
  if (address === '') {
    strPath =
      'https://raw.githubusercontent.com/LoveBoatDEX/token/28db238c260312f87daf2879788fa710ced28f8f/matic-unselected.svg'
  }

  switch (chainId) {
    case ChainId.MATIC: {
      strPath = `https://raw.githubusercontent.com/LoveBoatDEX/assets/main/blockchains/polygon/assets/${address}/logo.png`
      break;  
    }
    case ChainId.MUMBAI: {
      switch (symbol) {
        case 'MATIC': {
          strPath = 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png'
          break
        }
        case 'WMATIC': {
          strPath = 'https://assets.coingecko.com/coins/images/14073/large/matic.jpg'
          break
        }
        case 'WBTC': {
          strPath = 'https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png'
          break
        }
        case 'LOVE': {
          strPath =
            'https://raw.githubusercontent.com/LoveBoatDEX/token/5afc8e94b3c94d613c52d20432946d3964cc6f0c/LOVE.png'
          break
        }
        case 'WETH': {
          strPath = 'https://assets.coingecko.com/coins/images/279/large/ethereum.png'
          break
        }
        case 'DAI': {
          strPath = 'https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png'
          break
        }
        case 'USDC': {
          strPath = 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png'
          break
        }
        case 'USDT': {
          strPath = 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png'
          break
        }
        case 'WOOFY': {
          strPath = 'https://assets.coingecko.com/coins/images/13970/large/1_pOU6pBMEmiL-ZJVb0CYRjQ.png'
          break
        }
        case 'SUSHI': {
          strPath = 'https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png'
          break
        }
        case 'xoLOVE': {
          strPath =
            'https://raw.githubusercontent.com/LoveBoatDEX/token/4b8d27c4c696a6d303e1b248d774c0c12f76251b/xoLOVE.png'
          break
        }
        case 'renDOGE': {
          strPath = 'https://assets.coingecko.com/coins/images/13796/large/renDOGE.png'
          break
        }
        default: {
          strPath = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/${address}/logo.png`
        }
      }
      break
    }
    default: {
      switch (symbol) {
        case 'MATIC': {
          strPath = 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png'
          break
        }
        default: {
          strPath = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`
        }
      }
      break
    }
  }
  return strPath
}

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const { chainId } = useActiveWeb3React()
  const srcs: string[] = useMemo(() => {
    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, setTokenLogo(currency.address, chainId, currency.symbol)]
      }

      return [setTokenLogo(currency.address, chainId, currency.symbol)]
    }
    // return [setTokenLogo('', chainId, 'MATIC')]
    return []
  }, [currency, chainId, uriLocations])

  // return (
  //     <StyledEthereumLogo src={srcs[0]} size={size} style={style} />
  // )
  if (currency === ETHER) {
    return (
      <StyledEthereumLogo src={EthereumLogo} /*alt={'styled ethereum logo'}*/ size={size} style={style} {...rest} />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} {...rest} />
}
