// import Loader from '../../components/Loader'
import { AutoColumn, InfoSection, InfoCard } from '../../components/Column'
import styled from 'styled-components'
import { useLoveBoatInfo } from '../../state/xolove/hooks'
import { TYPE } from '../../theme'
import LoveCard from '../../components/Dividends/LoveCard'
import { RowBetween } from '../../components/Row'
import { CardSection, CardNoise, CardBGImage } from '../../components/Rewards/styled'
import { XOLOVE } from '../../constants/tokens'
import { ExternalInfoButton } from '../../theme/components'

const PageWrapper = styled(AutoColumn)`
  max-width: 672px;
  width: 100%;
`

const LoveSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

export default function Dividends() {
  // staking info for connected account
  const loveBoatInfo = useLoveBoatInfo()

  return (
    <>
      <TYPE.pageTitle>Dividends</TYPE.pageTitle>
      <PageWrapper gap="lg" justify="center">
        <InfoSection gap="lg">
          <InfoCard>
            <CardBGImage />
            <CardNoise />
            <CardSection>
              <AutoColumn gap="lg" justify="center">
                <RowBetween>
                  <TYPE.white fontSize={24} fontWeight={1000} style={{ margin: '0 auto' }}>
                    Earn trading fees by staking your LOVE Tokens
                  </TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white fontSize={16} style={{ margin: '0 auto' }}>
                    Deposit your LOVE tokens in the XOLOVE Dividends contract. You will receive xoLOVE tokens that
                    represent your share. When you withdraw your LOVE tokens, you will receive your originally deposited
                    LOVE tokens as well as additional LOVE tokens from trading fees on the Love Boat Exchange.
                  </TYPE.white>
                </RowBetween>
                <ExternalInfoButton
                  href={
                    'https://loveboatexchange.gitbook.io/love-boat-exchange/love-boat-exchange/staking-love-for-xolove'
                  }
                >
                  Learn More
                </ExternalInfoButton>
              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </InfoCard>
        </InfoSection>
        <TYPE.subTitle>Staking LOVE for xoLOVE</TYPE.subTitle>
        <LoveSection>
          <LoveCard key={XOLOVE.address} loveBoatInfo={loveBoatInfo} />
        </LoveSection>
      </PageWrapper>
    </>
  )
}
