import { ETHER, JSBI, Pair, Percent, TokenAmount } from '@lbdex/sdk'
import { darken } from 'polished'
import { useState } from 'react'
import { ChevronUp, ChevronDown } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonSecondary, ButtonPoolCard, ButtonDetails, ButtonOrange, ButtonBlue } from '../Button'
import { CardNoise } from '../Rewards/styled'

// import { useColor } from '../../hooks/useColor'

import Card, { GreyCard, LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowBetweenStats, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
import { BIG_INT_ZERO } from '../../constants'
import { usePair } from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
// const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
const StyledPositionCard = styled(LightCard)`
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.bg1};
  margin: 0 !important;
  overflow: hidden;
`
const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px auto auto;
  align-items: center;
  text-align: left;
  padding: 0 0 0 .5rem;
  z-index: 1;
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        <GreyCard border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={16}>
                  Your Position
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={500} fontSize={24}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={20}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow style={{ borderBottom: '1px solid #ebebeb', paddingBottom: '5px' }}>
                <Text fontSize={16} fontWeight={500}>
                  Your pool share:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow style={{ borderBottom: '1px solid #ebebeb', paddingBottom: '5px' }}>
                <Text fontSize={16} fontWeight={500}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow style={{ borderBottom: '1px solid #ebebeb', paddingBottom: '5px' }}>
                <Text fontSize={16} fontWeight={500}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </GreyCard>
      ) : (
        <LightCard>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '}
            By adding liquidity you&apos;ll earn 0.25% of all trades on this pair proportional to your share of the
            pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </TYPE.subHeader>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border, stakedBalance }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(true)

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  // const backgroundColor = useColor(pair?.token0)
  const token0 = pair.token0
  const token1 = pair.token1

  const WETH = currency0 === ETHER ? token0 : token1

  // const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)

  const [, stakingTokenPair] = usePair(currency0, currency1)

  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalPoolTokens && stakingTokenPair) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(totalPoolTokens.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalPoolTokens.raw
      )
    )
  }

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const token0USDPrice = useUSDCPrice(token0)
  const token1USDPrice = useUSDCPrice(token1)

  return (
    <ButtonPoolCard>
      <StyledPositionCard border={border} /* bgColor={backgroundColor} */>
        <CardNoise />
        <AutoColumn gap="12px">
        <TopSection>
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20} />
          <TYPE.textmode fontWeight={700} fontSize={22}>
            {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
          </TYPE.textmode>
          <ButtonDetails onClick={() => setShowMore(!showMore)}>
              {showMore ? (
                  <>
                    <TYPE.textmode style={{ color: 'rgb(241, 107, 162)', textDecoration: 'none' }}>Hide</TYPE.textmode> 
                    <ChevronUp size="30" style={{ marginLeft: '10px', color: 'rgb(241, 107, 162)' }} />
                  </>
                ) : (
                  <>
                    <TYPE.textmode style={{ color: 'rgb(241, 107, 162)', textDecoration: 'none' }}>Details</TYPE.textmode>
                    <ChevronDown size="30" style={{ marginLeft: '10px', color: 'rgb(241, 107, 162)' }} />
                  </>
                )}
          </ButtonDetails>
      </TopSection>
          {showMore && (
            <AutoColumn gap="8px">
              <FixedHeightRow>
                <RowBetweenStats>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    {token0.symbol}
                  </TYPE.textmode>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    {token0USDPrice ? '$' + token0USDPrice.toSignificant(6) : '-'}
                  </TYPE.textmode>
                </RowBetweenStats>
              </FixedHeightRow>
              <FixedHeightRow>
                <RowBetweenStats>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    {token1.symbol}
                  </TYPE.textmode>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    {token1USDPrice ? '$' + token1USDPrice.toSignificant(6) : '-'}
                  </TYPE.textmode>
                </RowBetweenStats>
              </FixedHeightRow>

              <FixedHeightRow>
                <RowBetweenStats>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    Overall total pool tokens:
                  </TYPE.textmode>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    {totalPoolTokens ? totalPoolTokens.toSignificant(4) : '-'}
                  </TYPE.textmode>
                </RowBetweenStats>
              </FixedHeightRow>
              <FixedHeightRow>
                <RowBetweenStats>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    Overall total pool tokens in $:
                  </TYPE.textmode>
                  <TYPE.textmode fontSize={16} fontWeight={600}>
                    {valueOfTotalStakedAmountInUSDC
                      ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                      : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ${
                          valueOfTotalStakedAmountInWETH?.token.symbol
                        }`}
                  </TYPE.textmode>
                </RowBetweenStats>
              </FixedHeightRow>
              <FixedHeightRow>
                <RowBetweenStats>
                  <TYPE.textmode fontSize={16} fontWeight={500}>
                    Your total pool tokens:
                  </TYPE.textmode>
                  <TYPE.textmode fontSize={16} fontWeight={500}>
                    {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                  </TYPE.textmode>
                </RowBetweenStats>
              </FixedHeightRow>
              {stakedBalance && (
                <FixedHeightRow>
                  <RowBetweenStats>
                    <TYPE.textmode fontSize={16} fontWeight={500}>
                      Your pool tokens in rewards:
                    </TYPE.textmode>
                    <TYPE.textmode fontSize={16} fontWeight={500}>
                      {stakedBalance.toSignificant(4)}
                    </TYPE.textmode>
                  </RowBetweenStats>
                </FixedHeightRow>
              )}
              <FixedHeightRow>
                <RowBetweenStats>
                  <RowFixed>
                    <TYPE.textmode fontSize={16} fontWeight={500}>
                      Your pooled<CurrencyLogo size="16px" style={{ marginLeft: '8px' }} currency={currency0} /> {currency0.symbol}:
                    </TYPE.textmode>
                  </RowFixed>
                  {token0Deposited ? (
                    <RowFixed>
                      <TYPE.textmode fontSize={16} fontWeight={500} marginLeft={'6px'}>
                        {token0Deposited?.toSignificant(6)}
                      </TYPE.textmode>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </RowBetweenStats>
              </FixedHeightRow>

              <FixedHeightRow>
                <RowBetweenStats>
                  <RowFixed>
                    <TYPE.textmode fontSize={16} fontWeight={500}>
                      Your pooled<CurrencyLogo size="16px" style={{ marginLeft: '8px' }} currency={currency1} /> {currency1.symbol}:
                    </TYPE.textmode>
                  </RowFixed>
                  {token1Deposited ? (
                    <RowFixed>
                      <TYPE.textmode fontSize={16} fontWeight={500} marginLeft={'6px'}>
                        {token1Deposited?.toSignificant(6)}
                      </TYPE.textmode>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </RowBetweenStats>
              </FixedHeightRow>

              <FixedHeightRow>
                <TYPE.textmode fontSize={16} fontWeight={500}>
                  Your pool share:
                </TYPE.textmode>
                <TYPE.textmode fontSize={16} fontWeight={500}>
                  {poolTokenPercentage
                    ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
                    : '-'}
                </TYPE.textmode>
              </FixedHeightRow>
              <ButtonSecondary padding="8px" borderradius="8px">
                <ExternalLink
                  style={{ width: '100%', textAlign: 'center', color: '#f16ba2' }}
                  href={`https://info.loveboat.exchange/account/${account}`}
                >
                  View analytics 
                </ExternalLink>
              </ButtonSecondary>
            </AutoColumn>
          )}
           <RowBetween marginTop="10px">
              {/* Show Remove button only when there is a pool balance greater than 0. */}
              {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.raw, BIG_INT_ZERO) && (
                <ButtonBlue as={Link} to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
                  Remove
                </ButtonBlue>
              )}
              {/* Always show the option to add liquidity. */}
              <ButtonOrange as={Link} to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                Add Liquidity
              </ButtonOrange>
          </RowBetween>
          {/* If staked rewards are greater than 0, show button to manage. */}
          {stakedBalance && JSBI.greaterThan(stakedBalance.raw, BIG_INT_ZERO) && (
            <RowBetween marginTop="10px"> 
              <ButtonOrange
                padding="8px"
                borderradius="8px"
                as={Link}
                to={`/rewards/${currencyId(currency0)}/${currencyId(currency1)}`}
              >
                Manage Liquidity in Rewards
              </ButtonOrange>
            </RowBetween>
          )}
        </AutoColumn>
      </StyledPositionCard>
      </ButtonPoolCard>
  )
}
