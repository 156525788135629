import { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair, JSBI } from '@lbdex/sdk'
import { Link } from 'react-router-dom'
import ButtonFullPositionCard from '../../components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
import Card from '../../components/Card'
import { RowBetween, RowFixed } from '../../components/Row'
import { ButtonBlue, ButtonOrange /* , ButtonLightBlue */ } from '../../components/Button'
import { AutoColumn, InfoSection, InfoCard } from '../../components/Column'
import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
import { CardSection, CardNoise, CardBGImage } from '../../components/Rewards/styled'
import { useStakingInfo } from '../../state/stake/hooks'
import { BIG_INT_ZERO } from '../../constants'
import { ExternalInfoButton } from '../../theme/components'
// import { ChevronDown } from 'react-feather'
// import FullPositionCard from '../../components/PositionCard'
//import { Text } from 'rebass'

const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
`

const PoolSection = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
  border-radius: 12px;
  padding:16px 0;
`

const PositionCardSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  row-gap: 10px;
  width: 100%;
  justify-self: center;
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  padding-top: 20px;
`

const ResponsiveButtonPrimary = styled(ButtonOrange)`
  width: fit-content;
  padding: 8px 24px;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonBlue = styled(ButtonBlue)`
  width: fit-content;
  padding: 8px 24px;
  white-space:nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  //border: 1px solid ${({ theme }) => theme.text4};
  background-color: ${({ theme }) => theme.bg3};
  max-width: 672px;
  padding: 16px 12px;
  border-radius: 12px;
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  // console.log('Tracked token pairs (useTrackedTokenPairs - state/user/hooks.tsx')
  // console.log(trackedTokenPairs)

  // for a token pair, find the LP token for that token pair
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  // console.log('tokenPairsWithLiquidityTokens')
  // console.log(tokenPairsWithLiquidityTokens)

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )

  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  // console.log('liquidityTokensWithBalances')
  // console.log(liquidityTokensWithBalances)

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) => JSBI.greaterThanOrEqual(pool.stakedAmount.raw, BIG_INT_ZERO))
  const stakingPairs = usePairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })
  return (
    <>  
      <TYPE.pageTitle>Pool</TYPE.pageTitle>
      <PageWrapper gap="lg" justify="center">
        <InfoSection gap="lg">
          <InfoCard>
            <CardBGImage />
            <CardNoise />
            <CardSection>
              <AutoColumn gap="md" justify="center">
                <RowBetween>
                  <TYPE.white fontSize={24} fontWeight={1000} style={{ margin: '0 auto' }}>
                    Providing Liquidity
                  </TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white fontSize={16} style={{ margin: '0 auto' }}>
                    When you provide liquidity, you can earn a <b>0.25%</b> fee on all trades proportional to your share
                    of the pool! Fees are added to the pool, accrue in real time and can be claimed by withdrawing your
                    liquidity.
                  </TYPE.white>
                </RowBetween>
                <ExternalInfoButton href="https://loveboatexchange.gitbook.io/love-boat-exchange/love-boat-exchange/providing-liquidity">
                  Learn More
                </ExternalInfoButton>
              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </InfoCard>
        </InfoSection>
        <PoolSection gap="lg" justify="center">
        <TYPE.subTitle>Liquidity Pools</TYPE.subTitle>
          <ButtonRow>
            <ResponsiveButtonBlue as={Link} to="/create/MATIC">
              Create a Pair
            </ResponsiveButtonBlue>
            <ResponsiveButtonPrimary id="join-pool-button" as={Link} borderradius="12px" to="/add/MATIC">
              Add Liquidity
            </ResponsiveButtonPrimary>
          </ButtonRow>
          {!account ? (
            <RowBetween>
              <Card padding="40px">
                <TYPE.body color={theme.boldblue} fontSize={18} textAlign="center">
                  Connect to a wallet to view your liquidity.
                </TYPE.body>
              </Card>
            </RowBetween>
          ) : v2IsLoading ? (
            <RowBetween>
              <EmptyProposals>
                <TYPE.body color={theme.text1} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </EmptyProposals>
            </RowBetween>
          ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
            <>
              <PositionCardSection>
                {/* <ButtonLightBlue>
                  <RowBetween>
                    <ExternalLink href={'' + account} style={{ color: '#1b4f78' }}>
                      Account analytics and accrued fees (coming soon!)
                    </ExternalLink>
                    <ChevronDown
                      size={5}
                      style={{ backgroundColor: '#bee4f1', borderradius: '50px', height: '25px', width: '25px' }}
                    />
                  </RowBetween> 
                </ButtonLightBlue> */}
                {v2PairsWithoutStakedAmount.map((v2Pair) => (
                  <ButtonFullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
                {stakingPairs.map(
                  (stakingPair, i) =>
                    stakingPair[1] && ( // skip pairs that arent loaded
                      <ButtonFullPositionCard
                        key={stakingInfosWithBalance[i].stakingRewardAddress}
                        pair={stakingPair[1]}
                        stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                      />
                    )
                )}
              </PositionCardSection>
            </>
          ) : (
            <RowBetween>
              <EmptyProposals>
                <TYPE.body color={theme.text1} textAlign="center">
                  No liquidity found.
                </TYPE.body>
              </EmptyProposals>
            </RowBetween>
          )}

          {/* <AutoColumn justify={'center'} gap="md">
              <Text textAlign="center" fontSize={18} style={{ padding: '.5rem 0 .5rem 0', color: '#1b4f78'  }}>
                {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : "Don't see a pool you joined?"}{' '}
                <StyledInternalLink id="import-pool-link" style={{ borderBottom: '1px solid #5692ad', color: '#1b4f78'  }} to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                  {hasV1Liquidity ? 'Migrate now.' : 'Import it.'}
                </StyledInternalLink>
              </Text>
            </AutoColumn> */}
        </PoolSection>
      </PageWrapper>
    </>
  )
}
