import styled from 'styled-components'
import { ExternalLink, TYPE } from '../../theme'
import { RowBetween } from '../../components/Row'
import { AutoColumn } from '../../components/Column'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/Rewards/styled'
import { LOVE } from '../../constants/tokens'
import { useActiveWeb3React } from '../../hooks'
import { computeLoveCirculation } from '../../utils/computeLoveCirculation'
import useCurrentBlockTimestamp from '../../hooks/useCurrentBlockTimestamp'
import { ChainId, TokenAmount /*, JSBI*/ } from '@lbdex/sdk'
import { useTotalSupply } from '../../data/TotalSupply'
import { useMemo } from 'react'

const PageWrapper = styled(AutoColumn)`
  max-width: 672px;
  width: 100%;
  padding-top:14px;
`
const VoteCard = styled(DataCard)`
background-color: ${({ theme }) => theme.bg1};
color: ${({ theme }) => theme.primary3};
  overflow: hidden;
  
`
const InfoLinks = styled(ExternalLink)`
 color: ${({ theme }) => theme.text1};
 text-decoration: underline;
`


export default function Token() {
  const { chainId } = useActiveWeb3React()
  const love = chainId ? LOVE[chainId] : undefined
  const blockTimestamp = useCurrentBlockTimestamp()
  const totalSupply: TokenAmount | undefined = useTotalSupply(love)
  const circulation: TokenAmount | undefined = useMemo(
    () =>
      blockTimestamp && love && chainId === ChainId.MATIC ? computeLoveCirculation(love, blockTimestamp) : totalSupply,
    [blockTimestamp, chainId, totalSupply, love]
  )
  return (
    <>
    <TYPE.pageTitle>LOVE Token</TYPE.pageTitle>
      <PageWrapper gap="lg" justify="center">
      <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
          <AutoColumn gap="md">
              <RowBetween>
                  <TYPE.body fontSize={14}>Issue Time: 2021-07-02</TYPE.body>
              </RowBetween>
              <RowBetween>
                  <TYPE.body fontSize={14}>Total Supply: 1,000,000</TYPE.body>
              </RowBetween>
              <RowBetween>
                  <TYPE.body fontSize={14}>Circulation: {circulation?.toFixed(0, { groupSeparator: ',' })}</TYPE.body>
              </RowBetween>
              <RowBetween>
                  <TYPE.body fontSize={14}>Website: <InfoLinks href="https://www.loveboat.exchange">https://www.loveboat.exchange</InfoLinks></TYPE.body>
              </RowBetween>
              <RowBetween>
                  <TYPE.body fontSize={14}>Block Explorer: <InfoLinks href="https://polygonscan.com/address/0x69bde563680f580a2da5b5d4e202eca4fdf35664">https://polygonscan.com/address/0x69bde563680f580a2da5b5d4e202eca4fdf35664</InfoLinks></TYPE.body>
              </RowBetween>
              <RowBetween>
                  <TYPE.body fontSize={16} fontWeight={900}>Project Introduction</TYPE.body>
              </RowBetween>
              <RowBetween>
                  <TYPE.body fontSize={14}>LOVE is an ERC-20 standard decentralized cryptocurrency invented by a small startup company based in Philadelphia, PA called Fruitful Labs. LOVE’s derivative asset, xoLOVE, serves as a native governance token for our decentralized exchange (DEX) platform, Love Boat Exchange, which leverages the power of blockchain technology. Currently, LOVE has only been deployed as a digital asset on the Ethereum and Polygon (formerly Matic) Mainnets; however, there are plans to expand its outreach onto other chains, such as Arbitrium, Optimism, Fantom, Binance Side Chain, and more.</TYPE.body>
              </RowBetween>
              </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard>
      </PageWrapper>
    </>
  )
}