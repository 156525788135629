import { useState, useLayoutEffect } from 'react'
import { shade } from 'polished'
import Vibrant from 'node-vibrant'
import { hex } from 'wcag-contrast'
import { Token, ChainId } from '@lbdex/sdk'
import uriToHttp from 'utils/uriToHttp'
import useTheme from 'hooks/useTheme'

async function getColorFromToken(token: Token): Promise<string | null> {
  if (token.chainId === ChainId.RINKEBY && token.address === '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735') {
    return Promise.resolve('#FAAB14')
  }

  // Set path for logo image.
  let strPath = ''
  if (token.address === '') {
    strPath =
      'https://raw.githubusercontent.com/LoveBoatDEX/token/28db238c260312f87daf2879788fa710ced28f8f/matic-unselected.svg'
  }
  switch (token.chainId) {
    case ChainId.MATIC: {
      strPath = `https://raw.githubusercontent.com/LoveBoatDEX/assets/main/blockchains/polygon/assets/${token.address}/logo.png`
      break;  
    }
    case ChainId.MUMBAI: {
      switch (token.symbol) {
        case 'MATIC': {
          strPath = 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png'
          break
        }
        case 'WMATIC': {
          strPath = 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png'
          break
        }
        case 'LOVE': {
          strPath =
            'https://raw.githubusercontent.com/LoveBoatDEX/token/5afc8e94b3c94d613c52d20432946d3964cc6f0c/LOVE.png'
          break
        }
        case 'WETH': {
          strPath = 'https://assets.coingecko.com/coins/images/279/large/ethereum.png'
          break
        }
        case 'DAI': {
          strPath = 'https://assets.coingecko.com/coins/images/9956/large/dai-multi-collateral-mcd.png'
          break
        }
        case 'USDC': {
          strPath = 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png'
          break
        }
        case 'USDT': {
          strPath = 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png'
          break
        }
        case 'WOOFY': {
          strPath = 'https://assets.coingecko.com/coins/images/13970/large/1_pOU6pBMEmiL-ZJVb0CYRjQ.png'
          break
        }
        case 'SUSHI': {
          strPath = 'https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png'
          break
        }
        case 'xoLOVE': {
          strPath =
            'https://raw.githubusercontent.com/LoveBoatDEX/token/4b8d27c4c696a6d303e1b248d774c0c12f76251b/xoLOVE.png'
          break
        }
        case 'renDOGE': {
          strPath = 'https://assets.coingecko.com/coins/images/13796/large/renDOGE.png'
          break
        }
        default: {
          strPath = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/${token.address}/logo.png`
        }
      }
      break
    }
    default: {
      switch (token.symbol) {
        case 'MATIC': {
          strPath = 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png'
          break
        }
        default: {
          strPath = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${token.address}/logo.png`
        }
      }
      break
    }
  }
  const path = strPath

  return Vibrant.from(path)
    .getPalette()
    .then((palette) => {
      if (palette?.Vibrant) {
        let detectedHex = palette.Vibrant.hex
        let AAscore = hex(detectedHex, '#FFF')
        while (AAscore < 3) {
          detectedHex = shade(0.005, detectedHex)
          AAscore = hex(detectedHex, '#FFF')
        }
        return detectedHex
      }
      return null
    })
    .catch(() => null)
}

async function getColorFromUriPath(uri: string): Promise<string | null> {
  const formattedPath = uriToHttp(uri)[0]

  return Vibrant.from(formattedPath)
    .getPalette()
    .then((palette) => {
      if (palette?.Vibrant) {
        return palette.Vibrant.hex
      }
      return null
    })
    .catch(() => null)
}

export function useColor(token?: Token) {
  const [color, setColor] = useState('#274d73')

  useLayoutEffect(() => {
    let stale = false

    if (token) {
      getColorFromToken(token).then((tokenColor) => {
        if (!stale && tokenColor !== null) {
          setColor(tokenColor)
        }
      })
    }

    return () => {
      stale = true
      setColor('#274d73')
    }
  }, [token])

  return color
}

export function useListColor(listImageUri?: string) {
  const theme = useTheme()
  const [color, setColor] = useState(theme.green3)

  useLayoutEffect(() => {
    let stale = false

    if (listImageUri) {
      getColorFromUriPath(listImageUri).then((color) => {
        if (!stale && color !== null) {
          setColor(color)
        }
      })
    }

    return () => {
      stale = true
      setColor('#274d73')
    }
  }, [listImageUri])

  return color
}
