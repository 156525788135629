import { ChainId, TokenAmount } from '@lbdex/sdk'
import { useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logo from '../../assets/svg/Full-logo.svg'
import LogoDark from '../../assets/svg/Full-logo-WHITE.svg'
//import LogoMobile from '../../assets/svg/Full-logo.svg'
//import LogoDarkMobile from '../../assets/svg/Full-logo-WHITE.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances, useAggregateUniBalance } from '../../state/wallet/hooks'
import { CardNoise } from '../Rewards/styled'
import { CountUp } from 'use-count-up'
import { TYPE, ExternalLink } from '../../theme'
import { YellowCard } from '../Card'
import { Moon, Sun } from 'react-feather'
import MainMenu from '../Menu'
import { MobileNav, SocialNav, AppNav } from '../Menu'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
// import ClaimModal from '../claim/ClaimModal'
// import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
// import { useUserHasAvailableClaim } from '../../state/claim/hooks'
// import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
// import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import '@reach/dialog/styles.css'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 135px 1fr auto;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 3px -10px 29px #0000004a;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    //flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-self: flex;

//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     padding: 1.55rem 0 1.55rem 8.45rem;
//     justify-content: left;
//     display: flex;
// `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
display: none;
`};
`
const HeaderMobileNav = styled(Row)`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1.55rem 0 1.55rem 8.45rem;
    justify-content: flex-end;
    align-items: right;
    text-align: right;
    widtrh:100;
    display: flex;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 100px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const LoveAmount = styled(AccountElement)`
  color: white;
  padding: 4px 12px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: linear-gradient(to right, #f9aa59, #f170ab);
`

const LBDexWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const LBDexIcon = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  position: absolute;
  top: 15px;
  left: -3px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;

  img {
    width: 94px;
    position: relative;
    top: 0px;
  }

  transition: transform 0.3s ease;
  :hover {
    transform: scale(1.05);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }
  :visited {
    color: ${({ theme }) => theme.text2};
  }
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`

export const StyledMenuButton = styled.button`
  position: relative;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.MUMBAI]: 'Mumbai',
  [ChainId.MATIC]: 'Polygon',
  [ChainId.MAINNET]: 'Change Network'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  // const toggleClaimModal = useToggleSelfClaimModal()

  // const availableClaim: boolean = useUserHasAvailableClaim(account)

  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  // const showClaimsPopup = useShowClaimPopup()

  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'
  
  return (
    <HeaderFrame>
      {/* <ClaimModal /> */}
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <HeaderRow>
        <Title href=".">
          <LBDexIcon>
            <img src={darkMode ? LogoDark : Logo} alt="logo" />
          </LBDexIcon>
        </Title>
      </HeaderRow>

      <HeaderLinks>
        <HideSmall>
          <StyledNavLink id={`home-nav-link`} to={'/home'}>
            Home
          </StyledNavLink>
        </HideSmall>
        <HideSmall>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            {t('Swap')}
          </StyledNavLink>
        </HideSmall>
        <HideSmall>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/create') ||
              pathname.startsWith('/find')
            }
          >
            {t('Pool')}
          </StyledNavLink>
        </HideSmall>
        <HideSmall>
          <StyledNavLink id={`rewards-nav-link`} to={'/rewards'}>
            Rewards
          </StyledNavLink>
        </HideSmall>
        <HideSmall>
          <StyledNavLink id={`dividends-nav-link`} to={'/dividends'}>
            Dividends
          </StyledNavLink>
        </HideSmall>
        <HideSmall>
          <StyledExternalLink id={`charts-nav-link`} href={'https://info.loveboat.exchange'}>
            Charts
          </StyledExternalLink>
        </HideSmall>
        {/* <HideSmall>
          <StyledExternalLink id={`bridge-nav-link`} href={'https://wallet.polygon.technology/bridge/'}>
            Bridge
          </StyledExternalLink>
        </HideSmall> */}
      </HeaderLinks>
      <HeaderControls>
        <HeaderElement>
          
            {chainId && NETWORK_LABELS[chainId] && (
              <StyledExternalLink id={`network-link`} href={'https://loveboatexchange.gitbook.io/love-boat-exchange/guides/adding-and-switching-to-polygon-network-in-wallet'}>
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
              </StyledExternalLink>
            )}
        
          {/* {availableClaim && !showClaimPopup && (
            <LBDexWrapper onClick={toggleClaimModal}>
              <LoveAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming LOVE</Dots> : 'Claim LOVE'}
                </TYPE.white>
              </LoveAmount>
              <CardNoise />
            </LBDexWrapper>
          )} */}
          {
            /*!availableClaim &&*/ aggregateBalance && (
              <LBDexWrapper onClick={() => setShowUniBalanceModal(true)}>
                <LoveAmount active={!!account /*&& !availableClaim*/} style={{ pointerEvents: 'auto' }}>
                  {account && (
                    <HideSmall>
                      <TYPE.white
                        style={{
                          paddingRight: '.4rem',
                        }}
                      >
                        <CountUp
                          key={countUpValue}
                          isCounting
                          start={parseFloat(countUpValuePrevious)}
                          end={parseFloat(countUpValue)}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      </TYPE.white>
                    </HideSmall>
                  )}
                  LOVE
                </LoveAmount>
                <CardNoise />
              </LBDexWrapper>
            )
          }
          <HideSmall>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} MATIC
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HideSmall>
        </HeaderElement>
        <HeaderElementWrap>
          <HideSmall>
            <StyledMenuButton onClick={() => toggleDarkMode()}>
              {darkMode ? <Sun size={20} /> : <Moon size={20} />}
            </StyledMenuButton>
          </HideSmall>
          <MainMenu />
          <MobileNav />
        </HeaderElementWrap>
      </HeaderControls>
      <HeaderMobileNav>
        
        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} MATIC
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        <HideSmall>
          <SocialNav />
        </HideSmall>
        <HideSmall>
          <AppNav />
        </HideSmall>
      </HeaderMobileNav>
    </HeaderFrame>
  )
}
