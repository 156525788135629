import { ChainId, Token, WETH } from '@lbdex/sdk'
import { LOVE_POLYGON_ADDRESS, LOVE_MUMBAI_ADDRESS } from './index'

// A list of tokens by chain.
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC')

// Mumbai Tokens
// export const XOLOVE = new Token(ChainId.MUMBAI,'0xbd7b69b08af0478002e267d70c7d2a490dc64ca5',18,'xoLOVE','Love Boat xoLOVE')
export const TLOVE = new Token(ChainId.MUMBAI, '0x4650245dc4DFaFDb208306c2482aFCA5401Ee908', 18, 'LOVE', 'Test1 Love')
export const WBTC_MUMBAI = new Token(ChainId.MUMBAI, '0x0d787a4a1548f673ed375445535a6c7a1ee56180', 18, 'WBTC', 'WBTC')
export const LOVE_MUMBAI = new Token(
  ChainId.MUMBAI,
  '0x9767d709409d9Ffb1751d6fa4f6c1601336B58a3',
  18,
  'LOVE',
  'Test Love 0x9767'
)
export const MUMBAIWMATIC = new Token(
  ChainId.MUMBAI,
  '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
  18,
  'WMATIC',
  'Mumbai Wrapped Matic'
)
export const MUMBAIMATIC = new Token(
  ChainId.MUMBAI,
  '0x0000000000000000000000000000000000001010',
  18,
  'MATIC',
  'Mumbai Matic'
)
export const WETH_MUMBAI = new Token(
  ChainId.MUMBAI,
  '0x3c68ce8504087f89c640d02d133646d98e64ddd9',
  18,
  'WETH',
  'Wrapped Ether'
)
export const DAI_MUMBAI = new Token(
  ChainId.MUMBAI,
  '0x001b3b4d0f3714ca98ba10f6042daebf0b1b7b6f',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC_MUMBAI = new Token(
  ChainId.MUMBAI,
  '0x2058a9d7613eee744279e3856ef0eada5fcbaa7e',
  6,
  'USDC',
  'USD Coin (PoS)'
)
export const USDT_MUMBAI = new Token(
  ChainId.MUMBAI,
  '0x9e38966f91274f418040241b39deaef43049c233',
  18,
  'USDT',
  'Teather USD'
)

// LOVE and XOLOVE
export const LOVE: { [chainId in ChainId]?: Token } = {
  [ChainId.MUMBAI]: new Token(ChainId.MUMBAI, LOVE_MUMBAI_ADDRESS, 18, 'LOVE', 'Love Token'),
  [ChainId.MATIC]: new Token(ChainId.MATIC, LOVE_POLYGON_ADDRESS, 18, 'LOVE', 'Love'),
}
export const XOLOVE = new Token(
  ChainId.MATIC,
  '0x38a09EF7300becc2dE6824423C8AB5C9b93e418c',
  18,
  'xoLOVE',
  'XOLOVE Dividends'
)

// MATIC Tokens
export const LOVE_MATIC = new Token(ChainId.MATIC, '0x69bdE563680f580A2da5b5d4E202ecA4FDF35664', 18, 'LOVE', 'Love')
export const WMATIC_MATIC = new Token(
  ChainId.MATIC,
  '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  18,
  'WMATIC',
  'Wrapped Matic'
)
export const WETH_MATIC = new Token(
  ChainId.MATIC,
  '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  18,
  'WETH',
  'Wrapped Ether'
)
export const DAI_MATIC = new Token(
  ChainId.MATIC,
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC_MATIC = new Token(
  ChainId.MATIC,
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  6,
  'USDC',
  'USD Coin (PoS)'
)
export const USDT_MATIC = new Token(
  ChainId.MATIC,
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  6,
  'USDT',
  'Teather USD'
)
export const WBTC_MATIC = new Token(
  ChainId.MATIC,
  '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const LINK_MATIC = new Token(
  ChainId.MATIC,
  '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
  18,
  'LINK',
  'ChainLink Token'
)
export const GHST_MATIC = new Token(
  ChainId.MATIC,
  '0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7',
  18,
  'GHST',
  'Aavegotchi GHST Token (PoS)'
)
export const AAVE_MATIC = new Token(ChainId.MATIC, '0xD6DF932A45C0f255f85145f286eA0b292B21C90B', 18, 'AAVE', 'Aave')
export const RENDOGE_MATIC = new Token(
  ChainId.MATIC,
  '0xcE829A89d4A55a63418bcC43F00145adef0eDB8E',
  8,
  'renDOGE',
  'renDOGE Token'
)
export const GRT_MATIC = new Token(
  ChainId.MATIC,
  '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
  18,
  'GRT',
  'Graph Token'
)
export const QUICK_MATIC = new Token(
  ChainId.MATIC,
  '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
  18,
  'QUICK',
  'Quickswap'
)
export const KOM_MATIC = new Token(ChainId.MATIC, '0xC004e2318722EA2b15499D6375905d75Ee5390B8', 8, 'KOM', 'Kommunitas')
const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.MUMBAI]: [WETH[ChainId.MUMBAI]],
  [ChainId.MATIC]: [WETH[ChainId.MATIC]],
  [ChainId.AVALANCHE]: [WETH[ChainId.AVALANCHE]],
  [ChainId.AVALANCHE_FUJI]: [WETH[ChainId.AVALANCHE_FUJI]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, COMP, MKR, WBTC],
  [ChainId.MUMBAI]: [...WETH_ONLY[ChainId.MUMBAI], LOVE_MUMBAI, WETH_MUMBAI, DAI_MUMBAI, USDC_MUMBAI, USDT_MUMBAI],
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], LOVE_MATIC, WETH_MATIC, DAI_MATIC, USDC_MATIC, USDT_MATIC],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]],
  },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC],
  [ChainId.MUMBAI]: [...WETH_ONLY[ChainId.MUMBAI], LOVE_MUMBAI, WETH_MUMBAI, DAI_MUMBAI, USDC_MUMBAI, USDT_MUMBAI],
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], LOVE_MATIC, WETH_MATIC, DAI_MATIC, USDC_MATIC, USDT_MATIC],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC],
  [ChainId.MUMBAI]: [...WETH_ONLY[ChainId.MUMBAI], LOVE_MUMBAI, WETH_MUMBAI, DAI_MUMBAI, USDC_MUMBAI, USDT_MUMBAI],
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], LOVE_MATIC, WETH_MATIC, DAI_MATIC, USDC_MATIC, USDT_MATIC],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin'),
    ],
    [USDC, USDT],
    [DAI, USDT],
  ],
}

// OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
]
