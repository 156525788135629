import { useRef } from 'react'
import {
  Radio,
  Send,
  MessageCircle,
  Type,
  Twitter,
  Linkedin,
  Menu,
  PlusCircle,
  Repeat,
  Anchor,
  DollarSign,
  Upload,
  LifeBuoy,
  BookOpen,
  TrendingUp,
  Moon, 
  Sun,
  Mail,
  GitHub,
  Home
} from 'react-feather'
import styled from 'styled-components'
import { ReactComponent as MenuIcon } from '../../assets/images/link.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { ExternalLink } from '../../theme'
import { NavLink } from 'react-router-dom'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
    height: 20px;
  }
`

const StyledNavButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
    height: 20px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const StyledNav = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 12rem;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  opacity: 95%;
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3.6rem;
  right: 0rem;
  z-index: 100;
   ${({ theme }) => theme.mediaWidth.upToMedium`
   top: -25.4rem;
 `};
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//   top: 4rem;
// `};
`

const NavFlyout = styled.span`
  min-width: 12rem;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  opacity: 95%;
  top: 3.6rem;
  right: 0rem;
  z-index: 100;
   ${({ theme }) => theme.mediaWidth.upToMedium`
   top: -25.4rem;
 `};
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//   top: 4rem;
// `};
`

const MobileNavItem = styled(NavLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`
const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

export function MobileNav() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MOBILENAV)
  const toggleMobileNav = useToggleModal(ApplicationModal.MOBILENAV)
  useOnClickOutside(node, open ? toggleMobileNav : undefined)
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  function closeModal() {
    toggleMobileNav();
  }

  function switchTheme() {
    closeModal();
    toggleDarkMode(); 
  }

  return (
    <StyledNav ref={node as any}>
      <StyledNavButton onClick={toggleMobileNav}>
        <Menu size={20} />
      </StyledNavButton>

      {open && (
        <NavFlyout>
          <MobileNavItem id={'theme-mobile-link'} to={'#'} onClick={() => switchTheme()}>
          {darkMode ? (
            <div><Sun size={13} />&nbsp;&nbsp;Light Theme</div>
          ) : (
            <div><Moon size={13} />&nbsp;&nbsp;Dark Theme</div>
          )}
          </MobileNavItem>
          <MobileNavItem id={'home-mobile-link'} to={'/home'} onClick={toggleMobileNav}>
            <Home size={13} />
            Home
          </MobileNavItem>
          <MobileNavItem id={'swap-mobile-link'} to={'/swap'} onClick={toggleMobileNav}>
            <Repeat size={13} />
            Swap
          </MobileNavItem>
          <MobileNavItem id={'pool-mobile-link'} to={'/pool'} onClick={toggleMobileNav}>
            <LifeBuoy size={13} />
            Pool
          </MobileNavItem>
          <MobileNavItem id={'rewards-mobile-link'} to={'/rewards'} onClick={toggleMobileNav}>
            <Anchor size={13} />
            Farm=Rewards
          </MobileNavItem>
          <MobileNavItem id={'dividends-mobile-link'} to={'/dividends'} onClick={toggleMobileNav}>
            <DollarSign size={13} />
            Stake=Dividends
          </MobileNavItem>
          <MobileNavItem id={'liquidity-mobile-link'} to={'/add/MATIC'} onClick={toggleMobileNav}>
            <PlusCircle size={13} />
            Add Liquidity
          </MobileNavItem>
          <MenuItem id={'charts-mobile-link'} href={'https://info.loveboat.exchange/'}>
            <TrendingUp size={13} />
            Charts
          </MenuItem>
          <MenuItem id={'stake-mobile-link'} href={'https://wallet.polygon.technology/bridge/'}>
            <Upload size={13} />
            Bridge
          </MenuItem>
          
        </NavFlyout>
      )}
    </StyledNav>
  )
}

export function AppNav() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.APPNAV)
  const toggleAppNav = useToggleModal(ApplicationModal.APPNAV)
  useOnClickOutside(node, open ? toggleAppNav : undefined)
  const [darkMode, toggleDarkMode2] = useDarkModeManager()

  function closeModal2() {
    toggleAppNav;
  }

  function switchTheme2() {
    closeModal2();
    toggleDarkMode2(); 
  }

  return (
    <StyledNav ref={node as any}>
      <StyledNavButton onClick={toggleAppNav}>
        <Menu size={20} />
      </StyledNavButton>

      {open && (
        <NavFlyout>
          <MobileNavItem id={'theme-nav-link'} to={'#'} onClick={() => switchTheme2()}>
          {darkMode ? (
            <div><Sun size={13} />&nbsp;&nbsp;Light Themes</div>
          ) : (
            <div><Moon size={13} />&nbsp;&nbsp;Dark Theme</div>
          )}
          </MobileNavItem>
          <MobileNavItem id={'home-nav-link'} to={'/home'} onClick={toggleAppNav}>
            <Home size={13} />
            Home
          </MobileNavItem>
          <MobileNavItem id={'swap-nav-link'} to={'/swap'} onClick={toggleAppNav}>
            <Repeat size={13} />
            Swap
          </MobileNavItem>
          <MobileNavItem id={'pool-nav-link'} to={'/pool'} onClick={toggleAppNav}>
            <LifeBuoy size={13} />
            Pool
          </MobileNavItem>
          <MobileNavItem id={'rewards-nav-link'} to={'/rewards'} onClick={toggleAppNav}>
            <Anchor size={13} />
            Farm-Rewards
          </MobileNavItem>
          <MobileNavItem id={'dividends-nav-link'} to={'/dividends'} onClick={toggleAppNav}>
            <DollarSign size={13} />
            Stake-Dividends
          </MobileNavItem>
          <MobileNavItem id={'liquidity-nav-link'} to={'/add/MATIC'} onClick={toggleAppNav}>
            <PlusCircle size={13} />
            Add Liquidity
          </MobileNavItem>
          <MenuItem id={'charts-nav-link'} href={'https://info.loveboat.exchange/'}>
            <TrendingUp size={13} />
            Charts
          </MenuItem>
          <MenuItem id={'bridge-nav-link'} href={'https://wallet.polygon.technology/bridge/'}>
            <Upload size={13} />
            Bridge
          </MenuItem>
        </NavFlyout>
      )}
    </StyledNav>
  )
}

export function SocialNav() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SOCIAL)
  const toggleSocial = useToggleModal(ApplicationModal.SOCIAL)
  useOnClickOutside(node, open ? toggleSocial : undefined)
  // const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggleSocial}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <MenuItem id={'social-announcements-link'} href={'https://t.me/loveboatdexannouncement'}>
            <Radio size={13} />
            Announcements
          </MenuItem>
          <MenuItem id={'social-discord-link'} href={'https://t.co/OopgYqYjxL?amp=1'}>
            <MessageCircle size={13} />
            Discord
          </MenuItem>
          <MenuItem id={'social-doc-link'} href={'https://loveboatexchange.gitbook.io/love-boat-exchange/'}>
            <BookOpen size={13} />
            Documentation
          </MenuItem>
          <MenuItem id={'social-email-link'} href={'mailto:support@loveboat.exchange'}>
            <Mail size={13} />
            Email
          </MenuItem>
          <MenuItem id={'social-code-link'} href={'https://github.com/LoveBoatDEX'}>
            <GitHub size={13} />
            Github
          </MenuItem>
          <MenuItem id={'social-linkedin-link'} href={'https://www.linkedin.com/company/fruitfullabs'}>
            <Linkedin size={13} />
            LinkedIn
          </MenuItem>
          <MenuItem id={'social-medium-link'} href={'https://medium.com/@LoveBoatDEX'}>
            <Type size={13} />
            Medium
          </MenuItem>
          <MenuItem id={'social-telegram-link'} href={'https://t.me/LoveBoatExchange'}>
            <Send size={13} />
            Telegram
          </MenuItem>
          <MenuItem id={'social-twitter-link'} href={'https://twitter.com/loveboatdex'}>
            <Twitter size={13} />
            Twitter
          </MenuItem>
          {/* {account && (
            <ButtonPrimary onClick={openClaimModal} padding="8px 16px" width="100%" borderradius="12px" mt="0.5rem">
              Claim LOVE
            </ButtonPrimary>
          )} */}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}

export default function MainMenu() {
  // const { account } = useActiveWeb3React()

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)
  // const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <MenuItem id={'menu-announcements-link'} href={'https://t.me/loveboatdexannouncement'}>
            <Radio size={13} />
            Announcements
          </MenuItem>
          <MenuItem id={'menu-discord-link'} href={'https://t.co/OopgYqYjxL?amp=1'}>
            <MessageCircle size={13} />
            Discord
          </MenuItem>
          <MenuItem id={'menu-doc-link'} href={'https://loveboatexchange.gitbook.io/love-boat-exchange/'}>
            <BookOpen size={13} />
            Documentation
          </MenuItem>
          <MenuItem id={'menu-email-link'} href={'mailto:support@loveboat.exchange'}>
            <Mail size={13} />
            Email
          </MenuItem>
          <MenuItem id={'menu-code-link'} href={'https://github.com/LoveBoatDEX'}>
            <GitHub size={13} />
            Github
          </MenuItem>
          <MenuItem id={'menu-linkedin-link'} href={'https://www.linkedin.com/company/fruitfullabs'}>
            <Linkedin size={13} />
            LinkedIn
          </MenuItem>
          <MenuItem id={'menu-medium-link'} href={'https://medium.com/@LoveBoatDEX'}>
            <Type size={13} />
            Medium
          </MenuItem>
          <MenuItem id={'menu-telegram-link'} href={'https://t.me/LoveBoatExchange'}>
            <Send size={13} />
            Telegram
          </MenuItem>
          <MenuItem id={'socmenuial-twitter-link'} href={'https://twitter.com/loveboatdex'}>
            <Twitter size={13} />
            Twitter
          </MenuItem>
          {/* {account && (
            <ButtonPrimary onClick={openClaimModal} padding="8px 16px" width="100%" borderradius="12px" mt="0.5rem">
              Claim LOVE
            </ButtonPrimary>
          )} */}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
