// import { useMemo } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween, AutoRow } from '../Row'
import styled from 'styled-components'
import { TYPE } from '../../theme'
import { /*LOVEBOAT_FEES_INFO,*/ LoveBoatProfitsInfo } from '../../state/xolove/hooks'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { XOLOVE } from '../../constants/tokens'
import XOLoveLogo from '../../assets/images/xolovetoken.png'
import { useCallback, useState } from 'react'
import { CardNoise, CardBGImage } from '../Rewards/styled'
import { JSBI } from '@lbdex/sdk'
import { useTokenBalance } from '../../state/wallet/hooks'
import UnstakingModal from '../Dividends/UnstakingModal'
import XoloveStakingModal from '../Dividends/XoloveStakingModal'
import { ButtonOrange, ButtonBlue /*, ButtonDarkBlue */ } from '../Button'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useActiveWeb3React } from '../../hooks'
import Card from '../Card'
// import DoubleCurrencyLogo from '../DoubleLogo'
// import { /*ETHER,*/ JSBI, TokenAmount } from '@lbdex/sdk'
// import { ButtonPrimary } from '../Button'
// import { useTotalSupply } from '../../data/TotalSupply'
// import { usePair } from '../../data/Reserves'
// import useUSDCPrice from '../../utils/useUSDCPrice'
// import { OutlineCard } from '../../components/Card'
// import { currencyId } from '../../utils/currencyId'
// import { useLoveBoatContract } from '../../hooks/useContract'
// import { /*NEVER_RELOAD, useMultipleContractSingleData,*/ useSingleCallResult } from '../multicall/hooks'

const DividendsRow = styled(RowBetween)`
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 5px 8px 5px;
`

const OrangeGradient = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  background: -webkit-linear-gradient(left, #faa452, #f16ba2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

// const OrangeGradientBtn = styled.h2`
//   font-size: 28px;
//   font-weight: 700;
//   margin: 0;
//   border: 10px solid;
//   border-image-slice: 1;
//   border-width: 3px;
//   border-radius: 25px;
//   padding-left: 12px;
//   padding-right: 12px;
//   padding-top: 6px;
//   padding-bottom: 6px;
//   border-radius: 35px;
//   --angle: 60deg;
//   border-image: linear-gradient(var(--angle), #faa452, #f16ba2) 1;
//   background: -webkit-linear-gradient(left, #faa452, #f16ba2);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// `

const StakingInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 10px;
  position: relative;
  background: #f5f5f5;
  border-radius: 12px;
  justify-content: center;
  gap: 12px;
  margin: 16px;
  padding: 16px;
`

// const StakingContainer = styled.div`
//   display: block;
//   width: 1150px;
//   position: relative;
//   background: #f5f5f5;
//   border-radius: 12px;
//   border-top-left-radius: 0px;
//   border-top-right-radius: 0px;
//   justify-content: space-between;
//   flex-direction: column;
//   gap: 12px;
//   margin-right: 1rem;
//   margin-left: 1rem;
//   margin: 0 auto;
//   margin-bottom: 1rem;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//   display: none;
// `};
// `

// const BlueInput = styled.div`
//   display: block;
//   width: auto;
//   position: relative;
//   background: #d1f5ff;
//   border-radius: 12px;
//   justify-content: space-between;
//   flex-direction: column;
//   gap: 12px;
//   margin-bottom: 1rem;
//   margin-right: 1rem;
//   margin-left: 1rem;
//   margin: 0 auto;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//   display: none;
// `};
// `

// const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
//   border-radius: 12px;
//   width: 100%;
//   overflow: hidden;
//   position: relative;
//   opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
//   background: #fff;
//   color: #${({ showBackground }) =>
//       showBackground &&
//       `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
//     0px 24px 32px rgba(0, 0, 0, 0.01);`};
// `

// const TopSection = styled.div`
//   display: grid;
//   margin-left: 100px;
//   grid-template-columns: 48px 1fr 120px;
//   grid-gap: 0px;
//   align-items: center;
//   padding: 1rem;
//   z-index: 1;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     grid-template-columns: 48px 1fr 96px;
//     margin-left: 0px;
//   `};
// `

const Wrapper = styled(AutoColumn)<{ showBackground: boolean }>`
  max-width: 1200px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function LoveCard({ loveBoatInfo }: { loveBoatInfo: LoveBoatProfitsInfo | undefined }) {
  const xoLoveCurrency = unwrappedToken(XOLOVE)
  // const { chainId } = useActiveWeb3React()
  // const love = chainId ? LOVE[chainId] : undefined
  // const loveCurrency = love ? unwrappedToken(love) : undefined
  // const LoveBoatSC = useLoveBoatContract(XOLOVE.address, true)
  // @dev checking the user's wallet for xoLOVE to see if they have LOVE staked in LoveBoat.
  // TODO: We should switch this to use the method LOVEBalance instead.
  // const isStaking = Boolean(loveBoatInfo?.xoLoveBalance.greaterThan('0'))
  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  //   const stakingInfosWithBalance = stakingInfos?.filter((s) => JSBI.greaterThan(s.stakedAmount.raw, BIG_INT_ZERO))
  // console.log(stakingInfosWithBalance)

  // toggle copy if rewards are inactive
  //   const stakingRewardsExist = Boolean(typeof chainId === 'number' && (LOVEBOAT_FEES_INFO[chainId]?.length ?? 0) > 0)

  //   console.log(stakingRewardsExist)
  // detect existing unstaked LOVE Token position to show add button if none found

  const isStaking = true
  const { account } = useActiveWeb3React()
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, loveBoatInfo?.loveBalanceInLoveBoat?.token)
  // @dev TODO: update showAddLiquidityButton to "purchase LOVE if true"
  const showAddLiquidityButton = Boolean(
    loveBoatInfo?.loveBalanceInLoveBoat?.equalTo('0') && userLiquidityUnstaked?.equalTo('0')
  )
  // const showAddLiquidityButton = true
  // toggle for staking modal and unstaking modal @dev we want to show when user clicks deposit/manage
  const [showXoloveStakingModal, setShowXoloveStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const toggleWalletModal = useWalletModalToggle()
  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowXoloveStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  // const accountArg = useMemo(() => [account ?? undefined], [account])
  // const xoLoveBalance = useSingleCallResult(LoveBoatSC, 'balanceOf', accountArg)
  // const loveBalance = loveBoatInfo?.loveBalanceInLoveBoat.raw
  // const loveBalance = new TokenAmount(XOLOVE, JSBI(loveBoatInfo.loveBalanceInLoveBoat))
  // console.log(loveBalance)
  // const loveBalanceFormatted = loveBalance.toSignificant(4, { groupSeparator: ',' })
  // console.log(loveBalanceFormatted)
  // const totalSupplyOfStakingToken = useTotalSupply(loveBoatInfo.stakedAmount.token)
  // const [, stakingTokenPair] = usePair(...loveBoatInfo.tokens)
  // let returnOverMonth: Percent = new Percent('0')
  // let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  // if (totalSupplyOfStakingToken && stakingTokenPair) {
  //   // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
  //   valueOfTotalStakedAmountInWETH = new TokenAmount(
  //     WETH,
  //     JSBI.divide(
  //       JSBI.multiply(
  //         JSBI.multiply(loveBoatInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
  //         JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
  //       ),
  //       totalSupplyOfStakingToken.raw
  //     )
  //   )
  // }

  // get the USD value of staked WETH
  // const USDPrice = useUSDCPrice(WETH)
  // const valueOfTotalStakedAmountInUSDC =
  //   valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  return (
    <Wrapper showBackground={isStaking}>
      <CardBGImage desaturate />
      <CardNoise />

      {/* <TopSection>
         // @dev do we want this all on one page, or have an innerpage? 
        <StyledInternalLink to={'/love'} style={{ width: '100%' }}>
          <ButtonOrange padding="8px" borderradius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonOrange>
        </StyledInternalLink> 
      </TopSection> */}

      <Card style={{ display: 'grid', width: '100%', gridTemplateColumns: ' 1fr', gridGap: '40px' }}>
        {/* <AutoRow>
          <AutoColumn>
            <div
              style={{
                boxShadow: '1px 1px 10px 1px #00000014',
                borderradius: '100%',
                margin: '0 8px',
              }}
            >
              <img
                src={XOLoveLogo}
                style={{
                  position: 'relative',
                  width: '200px',
                  display: 'block',
                  padding: '40px',
                  textAlign: 'center',
                }}
              />
            </div>
          </AutoColumn>
        </AutoRow> */}
        <AutoRow style={{ paddingRight: '8px' }}>
          <DividendsRow>
            <TYPE.navy fontWeight={600} fontSize={32}>
              <img
                src={XOLoveLogo}
                alt="xoLove Logo"
                style={{
                  position: 'relative',
                  top: '12px',
                  height: '48px',
                  padding: '8px',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              />
              {xoLoveCurrency.symbol}
            </TYPE.navy>
          </DividendsRow>
          <DividendsRow>
            <TYPE.navy fontSize={18}> Total LOVE deposited in XOLOVE Dividends </TYPE.navy>
            <TYPE.navy>
              {loveBoatInfo?.loveBalanceInLoveBoat
                ? `${loveBoatInfo.loveBalanceInLoveBoat.toSignificant(4, { groupSeparator: ',' })} LOVE`
                : 'You are not on the Polygon network.'}
            </TYPE.navy>
          </DividendsRow>
          <DividendsRow>
            <TYPE.navy fontSize={18}> LOVE Rate </TYPE.navy>
            <TYPE.navy>
              {loveBoatInfo?.lovePerxoLove
                ? `${loveBoatInfo.lovePerxoLove.toSignificant(4, { groupSeparator: ',' })} LOVE / xoLOVE`
                : 'Please connect to the Polygon network.'}
            </TYPE.navy>
          </DividendsRow>
          <DividendsRow>
            <TYPE.navy fontSize={18}> xoLOVE Rate </TYPE.navy>
            <TYPE.navy>
              {loveBoatInfo?.xoLovePerLove
                ? `${loveBoatInfo.xoLovePerLove.toSignificant(4, { groupSeparator: ',' })} xoLOVE / LOVE`
                : 'Please connect to the Polygon network.'}
            </TYPE.navy>
          </DividendsRow>
          <DividendsRow>
            <TYPE.navy fontSize={18}> Your xoLOVE Balance </TYPE.navy>
            <TYPE.navy>
              {/* @dev: add logic to see if the user has LOVE in their wallet, if they do then tell user they can deposit LOVE for xoLOVE and earn trading fees */}
              {loveBoatInfo?.xoLoveBalance
                ? `${loveBoatInfo.xoLoveBalance.toSignificant(4, { groupSeparator: ',' })} xoLOVE`
                : 'Please connect to the Polygon network.'}
            </TYPE.navy>
          </DividendsRow>
        </AutoRow>
      </Card>
      <AutoColumn>
        <StakingInfoContainer>
          <AutoColumn justify="center">
            <OrangeGradient>XOLOVE Dividends</OrangeGradient>
            <TYPE.boldblue fontSize={28} fontWeight={700}>
              {loveBoatInfo?.xoLovePerLove
                ? `1 xoLOVE = ${loveBoatInfo.xoLovePerLove.toSignificant(4, { groupSeparator: ',' })} LOVE`
                : 'Please connect to the Polygon network.'}
            </TYPE.boldblue>
            <TYPE.boldblue fontSize={16}>Spread the LOVE</TYPE.boldblue>
          </AutoColumn>
          <AutoColumn>
            {/* <BlockColumn >
                <RowBetween >
                  <OrangeGradient>Staking APR</OrangeGradient>
                </RowBetween>
                <RowBetween>
                  <TYPE.boldblue fontSize={28} fontWeight={700}>
                    xx.xx%
                  </TYPE.boldblue>
                </RowBetween>
                <RowBetween>
                  <TYPE.boldblue fontSize={14}>
                    Yesterday&apos;s APR
                  </TYPE.boldblue>
                </RowBetween>
              </BlockColumn> */}
          </AutoColumn>
          <AutoColumn>
            <RowBetween>
              {!showAddLiquidityButton && loveBoatInfo?.loveBalanceInLoveBoat?.greaterThan(JSBI.BigInt(0)) && (
                <ButtonBlue padding="8px" margin="8px" borderradius="8px" onClick={() => setShowUnstakingModal(true)}>
                  Withdraw
                </ButtonBlue>
              )}
              {loveBoatInfo && (
                <ButtonOrange margin="8px" padding="8px" borderradius="8px" onClick={handleDepositClick}>
                  {loveBoatInfo.loveBalanceInLoveBoat?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit Love Tokens'}
                </ButtonOrange>
              )}
            </RowBetween>
            <RowBetween>
              {loveBoatInfo && (
                <>
                  <XoloveStakingModal
                    isOpen={showXoloveStakingModal}
                    onDismiss={() => setShowXoloveStakingModal(false)}
                    stakingInfo={loveBoatInfo}
                    userLiquidityUnstaked={userLiquidityUnstaked}
                  />
                  <UnstakingModal
                    isOpen={showUnstakingModal}
                    onDismiss={() => setShowUnstakingModal(false)}
                    stakingInfo={loveBoatInfo}
                  />
                </>
              )}
            </RowBetween>
            <RowBetween>
              {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
                <TYPE.main style={{ whiteSpace: 'nowrap', padding: '30px 0 0 0' }}>
                  {userLiquidityUnstaked.toSignificant(6)} LOVE Tokens available
                </TYPE.main>
              )}
            </RowBetween>
          </AutoColumn>
          {/* 
            <div style={{ margin: '0 auto' }}>
              <OrangeGradientBtn>
                {loveBoatInfo?.xoLovePerLove
                  ? `1 xoLOVE = ${loveBoatInfo.xoLovePerLove.toSignificant(4, { groupSeparator: ',' })} LOVE`
                  : 'Not available?'}
              </OrangeGradientBtn>
            </div>

            <div style={{ margin: '0 auto' }}>
              <ButtonBlue style={{ width: '150px' }}>View Stats</ButtonBlue>
            </div> */}
        </StakingInfoContainer>
      </AutoColumn>
      {/* Balance and Stake + Unstake Container */}
      {/* <StakingContainer>

      <div style={{ display: "grid", padding: "24px", gridTemplateColumns: "240px 830px", gridGap: 25 }}>

      <div>
        
        <TYPE.navy fontSize={15} fontWeight={700} style={{ textAlign: 'center' }}>BALANCE</TYPE.navy>
        <BlueInput><TYPE.navy fontSize={18} fontWeight={700} style={{padding: '16px', textAlign: 'center' }}>0 xoLOVE</TYPE.navy></BlueInput>
        <RowBetween style={{height: '16px'}}></RowBetween>
        <TYPE.navy fontSize={15} fontWeight={700} style={{ textAlign: 'center' }}>UNSTAKED</TYPE.navy>
        <BlueInput><TYPE.navy fontSize={18} fontWeight={700} style={{padding: '16px', textAlign: 'center' }}>0 LOVE</TYPE.navy></BlueInput>
      </div>

      <div style={{backgroundColor: '#fff', borderradius: '15px'}}>
      <div style={{ display: "grid", maxWidth: "600px", padding: "12px", margin: "0 auto", marginBottom: "32px", marginTop: "32px", gridTemplateColumns: "auto auto", gridGap: 25, borderBottom: '2px solid #1b4f78' }}>
        <div><TYPE.navy fontSize={20}>0 LOVE</TYPE.navy></div>
        <div><TYPE.navy fontSize={20}>Balance: 0</TYPE.navy></div>
      </div>
        <ButtonOrange style= {{maxWidth: '200px', margin: '0 auto' }}>Connect Wallet</ButtonOrange>
      </div>

      </div>

      </StakingContainer> */}

      {isStaking && (
        <>
          {/* <Break /> */}
          <BottomSection showBackground={false}>
            {/* <RowBetween>Staking Section</RowBetween> */}

            {/* <TYPE.black color={'white'} fontWeight={500}>
              <span>Your rate</span>
            </TYPE.black> */}

            {/* <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {loveBoatInfo
                ? loveBoatInfo.active
                  ? `${loveBoatInfo.rewardRate
                      ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                      ?.toSignificant(4, { groupSeparator: ',' })} LOVE / week`
                  : '0 LOVE / week'
                : '-'}
            </TYPE.black> */}
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
