import arbitrumLogo from 'assets/svg/arbitrum_logo.svg'
import optimismLogo from 'assets/svg/optimism_logo.svg'
import maticLogo from 'assets/svg/matic-coin.svg'
import avaxLogo from 'assets/svg/avalanche-avax-logo.svg'

export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,
  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,
  MUMBAI = 80001,
  MATIC = 137,
  AVALANCHE_FUJI = 43113,
  AVALANCHE = 43114,
}

export type SupportedL1ChainId =
  | SupportedChainId.MAINNET
  | SupportedChainId.ROPSTEN
  | SupportedChainId.RINKEBY
  | SupportedChainId.GOERLI
  | SupportedChainId.KOVAN

export type SupportedL2ChainId =
  | SupportedChainId.ARBITRUM_ONE
  | SupportedChainId.ARBITRUM_RINKEBY
  | SupportedChainId.OPTIMISM
  | SupportedChainId.OPTIMISTIC_KOVAN
  | SupportedChainId.MATIC
  | SupportedChainId.MUMBAI
  | SupportedChainId.AVALANCHE
  | SupportedChainId.AVALANCHE_FUJI

export const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
  [SupportedChainId.MAINNET]: 'Mainnet',
  [SupportedChainId.RINKEBY]: 'Rinkeby',
  [SupportedChainId.ROPSTEN]: 'Ropsten',
  [SupportedChainId.GOERLI]: 'Görli',
  [SupportedChainId.KOVAN]: 'Kovan',
  [SupportedChainId.ARBITRUM_ONE]: 'Arbitrum',
  [SupportedChainId.ARBITRUM_RINKEBY]: 'Arbitrum Rinkeby',
  [SupportedChainId.OPTIMISM]: 'Optimism',
  [SupportedChainId.OPTIMISTIC_KOVAN]: 'Optimistic Kovan',
  [SupportedChainId.MATIC]: 'Matic',
  [SupportedChainId.MUMBAI]: 'Mumbai',
} as const

export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
]

export const L2_CHAIN_IDS = [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
  SupportedChainId.OPTIMISM,
  SupportedChainId.OPTIMISTIC_KOVAN,
  SupportedChainId.MATIC,
  SupportedChainId.MUMBAI,
]
interface L1ChainInfo {
  docs: string
  explorer: string
  infoLink: string
  label: string
}
interface L2ChainInfo extends L1ChainInfo {
  bridge: string
  logoUrl: string
}

type ChainInfo = { [chainId in SupportedL2ChainId]: L2ChainInfo } &
  { [chainId in SupportedL1ChainId]: L1ChainInfo } & { [chainId: number]: L1ChainInfo | L2ChainInfo }

export const CHAIN_INFO: ChainInfo = {
  [SupportedChainId.ARBITRUM_ONE]: {
    bridge: 'https://bridge.arbitrum.io/',
    docs: 'https://offchainlabs.com/',
    explorer: 'https://explorer.arbitrum.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.ARBITRUM_ONE],
    logoUrl: arbitrumLogo,
  },
  [SupportedChainId.ARBITRUM_RINKEBY]: {
    bridge: 'https://bridge.arbitrum.io/',
    docs: 'https://offchainlabs.com/',
    explorer: 'https://explorer.arbitrum.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.ARBITRUM_RINKEBY],
    logoUrl: arbitrumLogo,
  },
  [SupportedChainId.MAINNET]: {
    docs: '',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: NETWORK_LABELS[SupportedChainId.MAINNET],
  },
  [SupportedChainId.RINKEBY]: {
    docs: '',
    explorer: 'https://rinkeby.etherscan.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.RINKEBY],
  },
  [SupportedChainId.ROPSTEN]: {
    docs: '',
    explorer: 'https://ropsten.etherscan.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.ROPSTEN],
  },
  [SupportedChainId.KOVAN]: {
    docs: '',
    explorer: 'https://kovan.etherscan.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.KOVAN],
  },
  [SupportedChainId.GOERLI]: {
    docs: '',
    explorer: 'https://goerli.etherscan.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.GOERLI],
  },
  [SupportedChainId.OPTIMISM]: {
    bridge: 'https://gateway.optimism.io/',
    docs: 'https://optimism.io/',
    explorer: 'https://optimistic.etherscan.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.OPTIMISM],
    logoUrl: optimismLogo,
  },
  [SupportedChainId.OPTIMISTIC_KOVAN]: {
    bridge: 'https://gateway.optimism.io/',
    docs: 'https://optimism.io/',
    explorer: 'https://optimistic.etherscan.io/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.OPTIMISTIC_KOVAN],
    logoUrl: optimismLogo,
  },
  [SupportedChainId.MATIC]: {
    bridge: 'https://wallet.polygon.technology/bridge/',
    docs: 'https://docs.matic.network/',
    explorer: 'https://polygonscan.com/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.MATIC],
    logoUrl: maticLogo,
  },
  [SupportedChainId.MUMBAI]: {
    bridge: 'https://wallet.polygon.technology/bridge/',
    docs: 'https://docs.matic.network/',
    explorer: 'https://mumbai.PolygonScan.com/',
    infoLink: '',
    label: NETWORK_LABELS[SupportedChainId.MUMBAI],
    logoUrl: maticLogo
  },
  [SupportedChainId.AVALANCHE]: {
    bridge: 'https://bridge.avax.network/',
    docs: 'https://docs.avax.network/',
    explorer: 'https://cchain.explorer.avax.network/',
    infoLink: 'https://www.avax.network/',
    label: NETWORK_LABELS[SupportedChainId.AVALANCHE],
    logoUrl: avaxLogo
  },
  [SupportedChainId.AVALANCHE_FUJI]: {
    bridge: 'https://bridge.avax.network/',
    docs: 'https://docs.avax.network/',
    explorer: 'https://cchain.explorer.avax-test.network/',
    infoLink: 'https://www.avax.network/',
    label: NETWORK_LABELS[SupportedChainId.AVALANCHE_FUJI],
    logoUrl: avaxLogo
  },
}
