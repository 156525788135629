import styled from 'styled-components'
import { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import { CardNoise } from '../Rewards/styled'
import { ButtonFeatureCard } from '../Button'
import { TYPE } from '../../theme'

const StyledPositionCard = styled(LightCard)`
  border: none;
  background-color: ${({ theme }) => theme.bg1};
  position: relative;
  overflow: hidden;
  min-height: 350px;
  align-items: center;
`
const FeatureIcon1 = styled.div`
  height: 145px;
  width: 125px;
  diplay: block;
  content: url(${({ theme }) => theme.featureIcon1});
  align-items: center;
  margin: 0 auto;
`
const FeatureIcon2 = styled.div`
  height: 145px;
  width: 125px;
  diplay: block;
  content: url(${({ theme }) => theme.featureIcon2});
  align-items: center;
  margin: 0 auto;
`
const FeatureIcon3 = styled.div`
  height: 145px;
  width: 125px;
  diplay: block;
  content: url(${({ theme }) => theme.featureIcon3});
  align-items: center;
  margin: 0 auto;
`
const FeatureIcon4 = styled.div`
  height: 145px;
  width: 125px;
  diplay: block;
  content: url(${({ theme }) => theme.featureIcon4});
  align-items: center;
  margin: 0 auto;
`
const FeatureIcon5 = styled.div`
  height: 145px;
  width: 125px;
  diplay: block;
  content: url(${({ theme }) => theme.featureIcon5});
  align-items: center;
  margin: 0 auto;
`
const FeatureIcon6 = styled.div`
  height: 145px;
  width: 125px;
  diplay: block;
  content: url(${({ theme }) => theme.featureIcon6});
  align-items: center;
  margin: 0 auto;
`

export function Feature1() {
  return (
    <ButtonFeatureCard>
      <StyledPositionCard>
        <CardNoise />
        <AutoColumn gap="12px">
          <FeatureIcon1 />
          <TYPE.featureTitle>The Diamond Standard</TYPE.featureTitle>
          <TYPE.featureBody>
            We are the first AMM to implement our smart contracts and interface in the EIP-2535: Diamonds, Multi-Facet
            Proxy. We are exploring DAO governance possibilities with its easy upgradability pattern.
          </TYPE.featureBody>
        </AutoColumn>
      </StyledPositionCard>
    </ButtonFeatureCard>
  )
}

export function Feature2() {
  return (
    <ButtonFeatureCard>
      <StyledPositionCard>
        <CardNoise />
        <AutoColumn gap="12px">
          <FeatureIcon2 />
          <TYPE.featureTitle>Polygon Blockchain</TYPE.featureTitle>
          <TYPE.featureBody>
            We use the Polygon blockchain: An alternative to Ethereum that resides on the same network and has all the
            advantages of Ethereum built into it but with much lower fees.
          </TYPE.featureBody>
        </AutoColumn>
      </StyledPositionCard>
    </ButtonFeatureCard>
  )
}

export function Feature3() {
  return (
    <ButtonFeatureCard>
      <StyledPositionCard>
        <CardNoise />
        <AutoColumn gap="12px">
          <FeatureIcon3 />
          <TYPE.featureTitle>Secured and Decentralized</TYPE.featureTitle>
          <TYPE.featureBody>
            Our liquidity pools are both permissionless and trustless. Love Boat Exchange does not control your selected
            transactions or your funds on the exchange.
          </TYPE.featureBody>
        </AutoColumn>
      </StyledPositionCard>
    </ButtonFeatureCard>
  )
}
export function Feature4() {
  return (
    <ButtonFeatureCard>
      <StyledPositionCard>
        <CardNoise />
        <AutoColumn gap="12px">
          <FeatureIcon4 />
          <TYPE.featureTitle>The Diamond Bridge</TYPE.featureTitle>
          <TYPE.featureBody>
            We are creating the first custom Diamond standard brige to the Polygon network directly from the LOVE Boat
            Exchange interface.
          </TYPE.featureBody>
        </AutoColumn>
      </StyledPositionCard>
    </ButtonFeatureCard>
  )
}

export function Feature5() {
  return (
    <ButtonFeatureCard>
      <StyledPositionCard>
        <CardNoise />
        <AutoColumn gap="12px">
          <FeatureIcon5 />
          <TYPE.featureTitle>Fruitful Metaverse</TYPE.featureTitle>
          <TYPE.featureBody>
            You&apos;ll need 10 Love Tokens in order to purchase a Fruitful base character NFT. Love Tokens used to
            purchase Fruitful base character NFTs will be burned, making the Love Token&apos;s supply deflationary.
          </TYPE.featureBody>
        </AutoColumn>
      </StyledPositionCard>
    </ButtonFeatureCard>
  )
}

export function Feature6() {
  return (
    <ButtonFeatureCard>
      <StyledPositionCard>
        <CardNoise />
        <AutoColumn gap="12px">
          <FeatureIcon6 />
          <TYPE.featureTitle>More Networks</TYPE.featureTitle>
          <TYPE.featureBody>
            Our DEV team is hard at work connecting to new Networks including: Avalanche, Arbitrum, Optimism, Fantom,
            More.{' '}
          </TYPE.featureBody>
        </AutoColumn>
      </StyledPositionCard>
    </ButtonFeatureCard>
  )
}
