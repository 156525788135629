import styled from 'styled-components'

import { AlertTriangle, X } from 'react-feather'
import { useURLWarningToggle, useURLWarningVisible } from '../../state/user/hooks'
import { isMobile } from 'react-device-detect'

const PhishAlert = styled.div<{ isActive: any }>`
  width: 100%;
  padding: 6px 6px;
  background: linear-gradient(to left, #faa452, #f16ba2);
  color: white;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
`

export const StyledClose = styled(X)`
  :hover {
    cursor: pointer;
  }
`

export default function URLWarning() {
  const toggleURLWarning = useURLWarningToggle()
  const showURLWarning = useURLWarningVisible()

  return isMobile? (
    <PhishAlert isActive={showURLWarning}>
      <div>
        <AlertTriangle style={{ marginRight: 6 }} size={12} /> Make sure the URL is www.loveboat.exchange
      <br/>
        <AlertTriangle style={{ marginRight: 6 }} size={12} /> Make sure you are on the Polygon network.
      </div>
      <StyledClose size={12} onClick={toggleURLWarning} />
    </PhishAlert>
  ) : window.location.hostname === 'www.loveboat.exchange' ? (
    <PhishAlert isActive={showURLWarning}>
      <div>
        <AlertTriangle style={{ marginRight: 6 }} size={12} /> Make sure the URL is www.loveboat.exchange
      <br/>
        <AlertTriangle style={{ marginRight: 6 }} size={12} /> Make sure you are on the Polygon network.
      </div>
      <StyledClose size={12} onClick={toggleURLWarning} />
    </PhishAlert>
  ) : null
}
