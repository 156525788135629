import styled from 'styled-components'
import { TYPE } from '../../theme'
import { RowBetween } from '../../components/Row'
import { AutoColumn } from '../../components/Column'

import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/Rewards/styled'


const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
  padding-top:14px;
`
const VoteCard = styled(DataCard)`
background-color: ${({ theme }) => theme.bg1};
color: ${({ theme }) => theme.primary3};
  overflow: hidden;
`

export default function Disclaimer() {

  return (
    <>
    <TYPE.pageTitle>Disclaimer</TYPE.pageTitle>
      <PageWrapper gap="lg" justify="center">
        <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.body fontSize={20} fontWeight={900}>Love Boat Exchange Protocol Disclaimer </TYPE.body>
              </RowBetween>
              <RowBetween>
                <TYPE.body fontSize={14}>
                Love Boat Exchange is a decentralized peer-to-peer protocol that people can use to create liquidity and trade ERC-20 tokens. There is currently one versions of the Love Boat Exchange protocol (v1), which is made up of a set of smart contracts that are deployed on the Polygon and Ethereum Blockchains. Your use of the Love Boat Exchange protocol involves various risks, including, but not limited to, losses while digital assets are being supplied to the Love Boat Exchange protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Love Boat Exchange protocol, you should review the relevant documentation to make sure you understand how the Love Boat Exchange protocol works. Additionally, just as you can access email protocols such as SMTP through multiple email clients, you can access the Love Boat Exchange protocol through dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present. 
                </TYPE.body>
              </RowBetween>
 
              <RowBetween>
                <TYPE.body fontSize={14}>
                AS DESCRIBED IN THE LOVE BOAT EXCHANGE PROTOCOL LICENSES, THE LOVE BOAT EXCHANGE PROTOCOL IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. Although Fruitful Labs Inc. developed much of the code for the Love Boat Exchange protocol, it does not provide, own, or control the Love Boat Exchange protocol, which is run by smart contracts deployed on the Ethereum blockchain. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the LOVE governance token. No developer or entity involved in creating the Love Boat Exchange protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the Love Boat Exchange protocol, including any direct, indirect, incidental, special, exemplary, punitive, or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value. 
                </TYPE.body>
              </RowBetween>
        
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard>
      </PageWrapper>
    </>
  )
}
