import { AutoColumn, InfoSection, InfoCard } from '../../components/Column'
import styled from 'styled-components'
import { STAKING_REWARDS_INFO, useStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard from '../../components/Rewards/PoolCard'
import { RowBetween } from '../../components/Row'
import { CardSection, CardNoise, CardBGImage } from '../../components/Rewards/styled'
import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { JSBI } from '@lbdex/sdk'
import { BIG_INT_ZERO } from '../../constants'
import { OutlineCard } from '../../components/Card'
import { ExternalInfoButton /*, ExternalInfoButtonForUnstaking*/ } from '../../theme/components'

const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
`

const RewardsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  width: 100%;
  justify-self: center;
`

const RewardsSection = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
  border-radius: 12px;
  padding: 16px 0;
`

export default function Rewards() {
  const { chainId } = useActiveWeb3React()

  // staking info for connected account
  const stakingInfos = useStakingInfo()
  // console.log(stakingInfos)
  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  const stakingInfosWithBalance = stakingInfos?.filter((s) => JSBI.greaterThan(s.stakedAmount.raw, BIG_INT_ZERO))
  // console.log(stakingInfosWithBalance)

  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  // console.log(stakingRewardsExist)

  return (
    <>
      <TYPE.pageTitle>Rewards</TYPE.pageTitle>
      <PageWrapper gap="lg" justify="center">
        <InfoSection gap="lg">
          <InfoCard>
            <CardBGImage />
            <CardNoise />
            <CardSection>
              <AutoColumn gap="md" justify="center">
                <RowBetween>
                  <TYPE.white fontSize={24} fontWeight={1000} style={{ margin: '0 auto' }}>
                    Farming for Liquidity Providers
                  </TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white fontSize={16} style={{ margin: '0 auto' }}>
                    Farming our rewards through our farms will allow you to gain more LOVE tokens! To be able to farm,
                    you will first have needed to provide liquidity. Then simply deposit your LOVE Liquidity Provider
                    (LOVE LP) tokens to receive LOVE tokens!
                  </TYPE.white>
                </RowBetween>{' '}
                <ExternalInfoButton href="https://loveboatexchange.gitbook.io/love-boat-exchange/love-boat-exchange/farming-on-love-boat">
                  Learn More
                </ExternalInfoButton>
              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </InfoCard>
        </InfoSection>
        {/* <InfoSection gap="lg">
          <InfoCard>
            <CardBGImage />
            <CardNoise />
            <CardSection>
              <AutoColumn gap="md" justify="center">
                <RowBetween>
                  <TYPE.white fontSize={24} fontWeight={1000} style={{ margin: '0 auto' }}>
                    ATTENTION LOVE Liquidity Providers:
                  </TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.navy style={{ margin: '0 auto' }}>
                    We introduced new rewards contracts. If you staked your LOVE LP tokens, you need to unstake them
                    from the old rewards contracts and stake them in the new ones below!
                  </TYPE.navy>
                </RowBetween>
                <ExternalInfoButtonForUnstaking href="https://lbdex-ui-ieqfms2ub-loveboatdex.vercel.app/#/rewards">
                  Unstake from old rewards contracts here
                </ExternalInfoButtonForUnstaking>
                <ExternalInfoButtonForUnstaking href="https://loveboatdex.medium.com/how-to-migrate-love-lp-tokens-777dd41c455">
                  Read a detailed explanation here
                </ExternalInfoButtonForUnstaking>
              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </InfoCard>
        </InfoSection> */}

        <CardSection>
          <AutoColumn gap="md" justify="center">
            <RowBetween>
              <Countdown exactEnd={stakingInfos?.[0]?.periodFinish} />
            </RowBetween>
          </AutoColumn>
        </CardSection>
        <TYPE.subTitle>Participating Pools</TYPE.subTitle>
        <RewardsSection>
          <RewardsContent>
            {stakingRewardsExist && stakingInfos?.length === 0 ? (
              <Loader style={{ margin: 'auto' }} />
            ) : !stakingRewardsExist ? (
              <OutlineCard>
                <TYPE.body textAlign="center">No active pools.</TYPE.body>
              </OutlineCard>
            ) : stakingInfos?.length !== 0 && stakingInfosWithBalance.length === 0 ? (
              // <OutlineCard>No active pools</OutlineCard>
              stakingInfos?.map((stakingInfo) => {
                // stakingInfosWithBalance?.map((stakingInfo) => {
                // need to sort by added liquidity here
                return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              })
            ) : (
              // (console.log(stakingInfos[0]), (<OutlineCard>No active pools</OutlineCard>))
              // stakingInfosWithBalance?.map((stakingInfo) => {
              //   // need to sort by added liquidity here
              //   return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              // })
              stakingInfos?.map((stakingInfo) => {
                // stakingInfosWithBalance?.map((stakingInfo) => {
                // need to sort by added liquidity here
                return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              })
            )}
          </RewardsContent>
        </RewardsSection>
      </PageWrapper>
    </>
  )
}
