import { ChainId, TokenAmount /*, JSBI*/ } from '@lbdex/sdk'
import { X } from 'react-feather'
import styled from 'styled-components'
import tokenLogo from '../../assets/images/loveboat-token-logo.png'
import { LOVE } from '../../constants/tokens'
import { useActiveWeb3React } from '../../hooks'
import { useTotalUniEarned } from '../../state/stake/hooks'
import { useAggregateUniBalance, useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, StyledInternalLink, TYPE, UniTokenAnimated } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { Break, CardBGImage, CardNoise, CardSection, DataCard } from '../Rewards/styled'
// import { computeUniCirculation } from '../../utils/computeUniCirculation'
import { computeLoveCirculation } from '../../utils/computeLoveCirculation'
import useUSDCPrice from '../../utils/useUSDCPrice'
// import { useMerkleDistributorContract } from '../../hooks/useContract'
import useCurrentBlockTimestamp from '../../hooks/useCurrentBlockTimestamp'
import { useMemo } from 'react'
// import { LoadingView } from 'components/ModalViews'
import { useTotalSupply } from '../../data/TotalSupply'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

const ModalUpper = styled(DataCard)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #65bad4 0%, #17647e 100%);
  padding: 0.5rem;
`

const StyledClose = styled(X)`
  position: absolute;
  right: 16px;
  top: 16px;

  :hover {
    cursor: pointer;
  }
`

/**
 * Content for balance stats modal
 */
export default function UniBalanceContent({ setShowUniBalanceModal }: { setShowUniBalanceModal: any }) {
  const { account, chainId } = useActiveWeb3React()
  const love = chainId ? LOVE[chainId] : undefined

  const total = useAggregateUniBalance()
  const loveBalance: TokenAmount | undefined = useTokenBalance(account ?? undefined, love)
  const loveToClaim: TokenAmount | undefined = useTotalUniEarned()

  const totalSupply: TokenAmount | undefined = useTotalSupply(love)
  const lovePrice = useUSDCPrice(love)
  const blockTimestamp = useCurrentBlockTimestamp()

  // const unclaimedUni = useTokenBalance(useMerkleDistributorContract()?.address, uni)
  /// unclaimed amounts
  // 1. check all vesting contracts.
  // 2. subtract the LOVE tokens that have not been claimed yet from vesting contracts that are eligible to be claimed.
  // 3. 2, except with LOVE tokens from rewards contracts

  /// @dev uni's circulating supply calculation
  // const circulation: TokenAmount | undefined = useMemo(
  //   () =>
  //     blockTimestamp && uni && chainId === ChainId.MAINNET
  //       ? computeUniCirculation(uni, blockTimestamp, unclaimedUni)
  //       : totalSupply,
  //   [blockTimestamp, chainId, totalSupply, unclaimedUni, uni]
  // )

  const circulation: TokenAmount | undefined = useMemo(
    () =>
      blockTimestamp && love && chainId === ChainId.MATIC ? computeLoveCirculation(love, blockTimestamp) : totalSupply,
    [blockTimestamp, chainId, totalSupply, love]
  )

  let circulatingLove: TokenAmount | undefined
  if (circulation && love && chainId) {
    circulatingLove = new TokenAmount(love, circulation.raw)
  }
  const circulatingSupplyMarketCap = circulatingLove && lovePrice?.quote(circulatingLove)

  // const circulatingSupplyMarketCap = lovePrice?.adjusted.multiply(JSBI.BigInt(circulation?.raw))

  // const circulatingSupplyMarketCap: TokenAmount | undefined = useMemo(
  //   () =>
  //     blockTimestamp && love && circulation && chainId  === ChainId.MATIC ? lovePrice?.adjusted.multiply(JSBI.BigInt(circulation?.raw) : totalSupply,
  //   [blockTimestamp, chainId, totalSupply, circulation, lovePrice]
  // )

  return (
    <ContentWrapper gap="lg">
      <ModalUpper>
        <CardBGImage />
        <CardNoise />
        <CardSection gap="md">
          <RowBetween>
            <TYPE.white color="white">Your LOVE Breakdown</TYPE.white>
            <StyledClose stroke="white" onClick={() => setShowUniBalanceModal(false)} />
          </RowBetween>
        </CardSection>
        <Break />
        {account && (
          <>
            <CardSection gap="sm">
              <AutoColumn gap="md" justify="center">
                <UniTokenAnimated width="48px" src={tokenLogo} />{' '}
                <TYPE.white fontSize={48} fontWeight={600} color="white">
                  {total?.toFixed(2, { groupSeparator: ',' })}
                </TYPE.white>
              </AutoColumn>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white color="white">Balance:</TYPE.white>
                  <TYPE.white color="white">{loveBalance?.toFixed(2, { groupSeparator: ',' })}</TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white color="white">Unclaimed:</TYPE.white>
                  <TYPE.white color="white">
                    {loveToClaim?.toFixed(4, { groupSeparator: ',' })}{' '}
                    {loveToClaim && loveToClaim.greaterThan('0') && (
                      <StyledInternalLink onClick={() => setShowUniBalanceModal(false)} to="/rewards">
                        (claim)
                      </StyledInternalLink>
                    )}
                  </TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
            <Break />
          </>
        )}
        <CardSection gap="sm">
          <AutoColumn gap="md">
            <RowBetween>
              <TYPE.white color="white">Love price:</TYPE.white>
              <TYPE.white color="white">${lovePrice?.toFixed(2) ?? '-'}</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">Estimated Love in circulation:</TYPE.white>
              <TYPE.white color="white">{circulation?.toFixed(0, { groupSeparator: ',' })}</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">Current daily Love emission rate:</TYPE.white>
              <TYPE.white color="white">443</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">Maximum daily Love emission rate:</TYPE.white>
              <TYPE.white color="white">648</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">Circulating market cap:</TYPE.white>
              <TYPE.white color="white">
                {'$' + circulatingSupplyMarketCap?.toFixed(2, { groupSeparator: ',' }) ?? '-'}
              </TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">Total Supply on Polygon</TYPE.white>
              <TYPE.white color="white">{totalSupply?.toFixed(0, { groupSeparator: ',' })}</TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="white">Total Supply on all networks</TYPE.white>
              <TYPE.white color="white">1,000,000</TYPE.white>
            </RowBetween>
            {love && love.chainId === ChainId.MATIC ? (
              <ExternalLink
                style={{ color: 'white', textAlign: 'center', marginTop: '16px', display: 'block', fontWeight: 'bold' }}
                href={`https://info.loveboat.exchange/`}
                // href={`https://info.loveboat.exchange/token/${love.address}`}
              >
                View LOVE Analytics
              </ExternalLink>
            ) : null}
          </AutoColumn>
        </CardSection>
      </ModalUpper>
    </ContentWrapper>
  )
}
