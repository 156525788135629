import { useState, useCallback } from 'react'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount } from '@lbdex/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useLoveTokenChildContract, useLoveBoatContract } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { splitSignature } from 'ethers/lib/utils'
import { LoveBoatProfitsInfo, useDerivedXoloveInfo } from '../../state/xolove/hooks'
// import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { XOLOVE } from '../../constants/tokens'

// import { useSingleCallResult } from '../../state/multicall/hooks'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

// @dev TODO: make stakingInfo prop more strongly typed (get rid of undefined type)
interface xoloveModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: LoveBoatProfitsInfo
  userLiquidityUnstaked: TokenAmount | undefined
}

export default function XoloveStakingModal({
  isOpen,
  onDismiss,
  stakingInfo,
  userLiquidityUnstaked,
}: xoloveModalProps) {
  const { account, chainId, library } = useActiveWeb3React()

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedXoloveInfo(
    typedValue,
    stakingInfo.loveBalanceInLoveBoat.token,
    userLiquidityUnstaked
  )

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  const LoveTokenChild = useLoveTokenChildContract()

  /// checking allowance
  // const accountArg = useMemo(() => [account ?? undefined], [account])
  // const allowanceLTC = LoveTokenChild ? LoveTokenChild.allowance(accountArg, XOLOVE.address) : null
  // console.log('THE ALLOWANCE IS: ')
  // console.log(allowanceLTC)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, XOLOVE.address)

  const isArgentWallet = useIsArgentWallet()

  const LoveBoat = useLoveBoatContract(XOLOVE.address)
  // const inputs = useMemo(() => [accountArg, XOLOVE.address])
  // const inputs = [account ?? undefined, XOLOVE.address]
  // const allowance5 = useSingleCallResult(LoveBoat, 'allowance', inputs).result
  // console.log('THE ALLOWANCE5 IS: ')
  // console.log(allowance5)

  async function onStake() {
    setAttempting(true)
    if (LoveBoat && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        await LoveBoat.enter(`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 350000 })
      } else if (signatureData) {
        LoveBoat.enterWithPermit(
          `0x${parsedAmount.raw.toString(16)}`,
          signatureData.deadline,
          signatureData.v,
          signatureData.r,
          signatureData.s,
          { gasLimit: 350000 }
        )
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Deposit LOVE to earn trading fees. Receive xoLOVE tokens which represent your share of LOVE in this contract.`,
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {
        setAttempting(false)
        throw new Error('Attempting to deposit LOVE without approval or a signature. Please contact support.')
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  async function onAttemptToApprove() {
    if (!LoveTokenChild || !library || !deadline) throw new Error('missing dependencies')
    // if (!LoveTokenChild) throw new Error('LOVE token contract is not loading')
    const liquidityAmount = parsedAmount
    if (!liquidityAmount) throw new Error('missing liquidity (LOVE Token) amount')

    if (isArgentWallet) {
      return approveCallback()
    }

    // try to gather a signature for permission
    const nonce = await LoveTokenChild.nonces(account)

    // console.log('LoveTokenChild nonces for account:')
    // console.log(nonce)
    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ]
    const domain = {
      name: 'Love',
      version: '2',
      chainId: chainId,
      verifyingContract: LoveTokenChild.address,
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ]
    const message = {
      owner: account,
      spender: XOLOVE.address,
      value: liquidityAmount.raw.toString(),
      nonce: nonce.toHexString(),
      deadline: deadline.toNumber(),
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: 'Permit',
      message,
    })

    library
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then((signature) => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: deadline.toNumber(),
        })
      })
      .catch((error) => {
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          approveCallback()
        }
      })
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.navy>Deposit LOVE for xoLOVE</TYPE.navy>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={stakingInfo.loveBalanceInLoveBoat.token}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to deposit: '}
            id="stake-love-for-xolove-token"
          />

          {/* <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <TYPE.navy fontWeight={600}>Weekly Rewards</TYPE.navy>
            </div>

            <TYPE.navy>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              LOVE / week
            </TYPE.navy>
          </HypotheticalRewardRate> */}

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              Approve
            </ButtonConfirmed>
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Deposit LOVE'}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Depositing LOVE for xoLOVE</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} LOVE Tokens</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} LOVE Tokens</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
