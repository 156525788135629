import { JSBI, Token, TokenAmount } from '@lbdex/sdk'
import { BigNumber } from 'ethers'
import { STAKING_GENESIS } from '../state/stake/hooks'

const TREASURY_VESTING_GENESIS = 1629831600 // 2021-08-24, Tuesday, 3 PM EST (may be adjusted to be between 08-19 to 08-24)

// seconds in a day
const SECONDS_IN_DAY = 60 * 60 * 24
const SECONDS_IN_WEEK = 60 * 60 * 24 * 7

// Vesting durations
const LIQUIDITY_MINING_REWARDS_END = TREASURY_VESTING_GENESIS + 1826 * SECONDS_IN_DAY // ~5 years
const GROWTH_TREASURY_END = TREASURY_VESTING_GENESIS + 731 * SECONDS_IN_DAY // ~2 years
const TEAM_AMOUNT_END = TREASURY_VESTING_GENESIS + 548 * SECONDS_IN_DAY // ~1.5 years
const LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1_END = STAKING_GENESIS + SECONDS_IN_WEEK // ~ 1 week
const LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1TO3_END = LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1_END + SECONDS_IN_WEEK * 2 // ~ 1 week
// Vested token amounts
const LIQUIDITY_MINING_REWARDS_AMOUNT = 895_700
const LOVE_BOAT_GROWTH_AMOUNT = 60_000
const TEAM_AMOUNT = 40_000

const LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1 = 1_400
const LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1TO3 = 2_800
const INITIAL_LIQUIDITY_LOVE_WETH = 70
const INITIAL_DEPOSIT_XOLOVE = 10
const FRUITFUL_KP_AMOUNT = 20

function withVesting(before: JSBI, time: BigNumber, amount: number, start: number, end: number, cliff?: number) {
  if (time.gt(start)) {
    if (time.gte(end)) {
      return JSBI.add(before, JSBI.BigInt(amount))
    } else {
      if ((typeof cliff === 'number' && time.gte(cliff)) || typeof cliff === 'undefined') {
        return JSBI.add(
          before,
          JSBI.divide(
            JSBI.multiply(JSBI.BigInt(amount), JSBI.BigInt(time.sub(start).toString())),
            JSBI.subtract(JSBI.BigInt(end), JSBI.BigInt(start))
          )
        )
      }
    }
  }
  return before
}

export function computeLoveCirculation(
  love: Token,
  blockTimestamp: BigNumber,
  unclaimedLove?: TokenAmount | undefined
): TokenAmount {
  // let wholeAmount = JSBI.add(JSBI.JSBI.BigInt(INITIAL_LIQUIDITY_LOVE_WETH), JSBI.BigInt(INITIAL_DEPOSIT_XOLOVE))
  let wholeAmount = JSBI.BigInt(INITIAL_LIQUIDITY_LOVE_WETH + INITIAL_DEPOSIT_XOLOVE + FRUITFUL_KP_AMOUNT)

  // liquidity mining rewards ~90% of total supply
  wholeAmount = withVesting(
    wholeAmount,
    blockTimestamp,
    LIQUIDITY_MINING_REWARDS_AMOUNT,
    TREASURY_VESTING_GENESIS,
    LIQUIDITY_MINING_REWARDS_END
  )

  // Love Boat Exchange partnerships and growth treasury 6% of total supply
  wholeAmount = withVesting(
    wholeAmount,
    blockTimestamp,
    LOVE_BOAT_GROWTH_AMOUNT,
    TREASURY_VESTING_GENESIS,
    GROWTH_TREASURY_END
  )

  // Love Boat team 4% of total supply
  wholeAmount = withVesting(wholeAmount, blockTimestamp, TEAM_AMOUNT, TREASURY_VESTING_GENESIS, TEAM_AMOUNT_END)

  // liquidity mining rewards initial first week! Week 1.
  wholeAmount = withVesting(
    wholeAmount,
    blockTimestamp,
    LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1,
    STAKING_GENESIS,
    LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1_END
  )
  // liquidity mining rewards week 1 through 3.
  wholeAmount = withVesting(
    wholeAmount,
    blockTimestamp,
    LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1TO3,
    LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1_END,
    LIQUIDITY_MINING_REWARDS_AMOUNT_WEEK1TO3_END
  )

  const total = new TokenAmount(love, JSBI.multiply(wholeAmount, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))))
  return unclaimedLove ? total.subtract(unclaimedLove) : total
}
