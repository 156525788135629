import styled from 'styled-components'
import UDArrrow from '../../../src/assets/svg/swap2.svg'

// Img currrently... should be converted to svg to take advantage of color changes.
const StyledUDArrow = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function UpDownArrow ({
    size = '50px',
    style,
    color,
    onClick
}: {
  size?: string
  style?: React.CSSProperties
  color?: string
  onClick?: ()=> void
}) {
    return <StyledUDArrow src={UDArrrow} size={size} style={style} color={color} onClick={onClick} />
}